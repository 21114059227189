import { DARK_MODE_FG } from '@paper/styles'
import { ReactNode } from 'react'
import { HStack, VSep, VStack } from '~src/components'
import { DarkMode } from '~src/utils/forceMode'
import { MenuDetailsSection } from '../menu/menuComponents'
import { LoaderMetadataModule } from './loaderMetadata'
import { CurriculumMenuModule } from './mmCurriculum'
import { NavMenu } from './mmLeftNav'
import { MenuModulePinGrid } from './mmPinGrid'
import { PublishMenuModule } from './mmPublish'
import { StudentWorkModule } from './mmStudentWork'

export type MainMenuProps = {
  keyboard?: ReactNode
  onClose?(): void
  pageIcon?: any
}

export function MainMenu(props: MainMenuProps) {
  return (
    <DarkMode>
      <HStack
        alignItems="start"
        bg="blackAlpha.800"
        color={DARK_MODE_FG}
        data-cy="menu2-container"
        overflow="auto"
        height="100vh"
      >
        <NavMenu {...props} />
        <VSep />
        <VStack
          alignItems="stretch"
          flexGrow={1}
          height="100%"
          overflow="hidden"
          pl={2}
          pb={4}
        >
          <StudentWorkModule keyboard={props.keyboard} />
          <CurriculumMenuModule />
          <PublishMenuModule />
          <MenuModulePinGrid keyboard={props.keyboard} />
          <MenuDetailsSection flexShrink={0} key="load" minHeight="110px">
            <LoaderMetadataModule />
          </MenuDetailsSection>
        </VStack>
      </HStack>
    </DarkMode>
  )
}
