import {
  AnswerKeyContentSet,
  DiffedAssessmentGroup,
  LastUpdate,
  Packetmeta,
  PacketmetaC2,
  PdfContentSet,
} from '@paper/schema'
import { defineApi, InvalidateOnMutationProps } from '../defineApi'
// todo: fix this import
import type { QMergeProps } from '@paper/api/server/utils/diffSatellite'
// todo: and this one
import type { Satellite } from '@paper/api/server/utils/satellite'

const invalidateProps: InvalidateOnMutationProps = {
  invalidateOnMutationSuccess: ['pe'],
  invalidateQueryFilters: { refetchType: 'all' },
}

export const pe_assessment_lookup = defineApi<{
  method: 'post'
  body: Satellite['assessment']['body'] & QMergeProps
  result: DiffedAssessmentGroup
}>({
  method: 'post',
  urlFactory: `lookupassessment`,
})

export const pe_getPackets = defineApi<{
  method: 'get'
  searchParams: { contentId: string }
  result: {
    packets: PacketmetaC2[]
    signedImageUrls: string[]
    signedPdfUrl: string
  }
}>({
  invalidateKey: `pe`,
  method: 'get',
  urlFactory: `getpackets`,
})

export const pe_submitPdf = defineApi<{
  method: 'post'
  body: {
    contentSet: PdfContentSet
  } & Pick<Packetmeta, 'contentId' | 'curriculumId'>
  result: {
    imageUploadUrls: string[]
    packetId: string
    pdfUploadHeaders: Record<string, string>
    pdfUploadUrl: string
    updateTime: LastUpdate['time']
  }
}>({
  ...invalidateProps,
  method: 'post',
  urlFactory: `submitpdf`,
})

export const pe_verifyPdf = defineApi<{
  method: 'post'
  body: { packetId: string; updateTime: LastUpdate['time'] }
  result: {}
}>({
  method: 'post',
  urlFactory: `submitpdf/verify`,
})

export const pe_submitAnswerKey = defineApi<{
  method: 'put'
  body: { contentSet: AnswerKeyContentSet } & Pick<Packetmeta, 'contentId'>
  result: {}
}>({
  ...invalidateProps,
  method: 'put',
  urlFactory: `submitanswerkey`,
})

export const pe_submitStds = defineApi<{
  method: 'put'
  body: { contentId: string; stds: string[] }
  result: {}
}>({
  ...invalidateProps,
  method: 'put',
  urlFactory: `submitpacketstds`,
})

export const pe_publish = defineApi<{
  method: 'put'
  body: Pick<Packetmeta, '_pub' | 'contentId' | 'curriculumId' | 'number'>
  result: {}
}>({
  ...invalidateProps,
  method: 'put',
  urlFactory: `publish`,
})

export const pe_submitCrossPacket = defineApi<{
  method: 'put'
  body: { packets: Pick<Packetmeta, 'id' | '_linkExternal'>[] }
  result: {}
}>({
  ...invalidateProps,
  method: 'put',
  urlFactory: `packetentry/links`,
})
