import { Box, BoxProps, CSSObject } from '@chakra-ui/react'
import { ANSWERKEY_BG, DEFAULT_FG } from '@paper/styles'
import { ReactNode } from 'react'
import { useMeasureWidth } from '~src/utils/useMeasure'

export const MAGIC_MAX_PASSAGE_LENGTH = 60

const FlexWrapColumnWorkaround: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  writingMode: 'vertical-lr',
  '> *': {
    writingMode: 'horizontal-tb',
  },
}

type AnswerKeyContainerProps = {
  children: ReactNode
  height?: BoxProps['height']
  minWidth?: BoxProps['minWidth']
  outerProps?: BoxProps // todo: survey of usage to figure out presets, but need to set padding for passage form question selector
  sizingMode?: AnswerKeySizingMode
}

/** @deprecated Addresses #197, but obviously messy! */
export type AnswerKeySizingMode =
  | 'dont-measure-width'
  | 'dont-measure-width-flex-shrink' // todo: need to do a survey of usage...presumably should just split...
  | 'measure-width'
  | 'measure-width-flex-shrink'

export const AnswerKeyContainer = (props: AnswerKeyContainerProps) => {
  const { children, height, minWidth, outerProps, sizingMode } = props

  // todo: messy! but need to allow measurement so the jump-to-q view can expand
  const { width, widthRef } = useMeasureWidth()

  // todo: seems to work more smoothly with 2 divs
  // the inner div gets to be as big as it wants
  // the outer div is sized to match
  return (
    <Box
      backgroundColor={ANSWERKEY_BG}
      data-cy="answerkey-container-outer"
      display="flex"
      flexShrink={sizingMode?.endsWith('flex-shrink') ? 1 : 0}
      height={height}
      maxH="100%"
      maxW="100%"
      minH="300px"
      minW={minWidth ?? '140px'}
      overflow="auto"
      userSelect="none"
      width={sizingMode === 'dont-measure-width' ? null : width}
      {...(outerProps ?? {})}
    >
      <Box
        alignItems="flex-start"
        alignContent="flex-start"
        data-cy="answerkey-container-inner"
        color={DEFAULT_FG}
        // @ts-expect-error
        ref={widthRef}
        sx={FlexWrapColumnWorkaround}
      >
        {children}
      </Box>
    </Box>
  )
}
