import { ChakraProvider, CSSReset } from '@chakra-ui/react'
import createCache from '@emotion/cache'
import { CacheProvider as EmotionCacheProvider, Global } from '@emotion/react'
import { chakraTheme, globalStyles } from '@paper/styles'
import { OverlayProvider } from '@react-aria/overlays'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'focus-visible/dist/focus-visible' // keyboard only focus rings
import { ReactNode } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import 'typeface-inconsolata'
import { UserProvider } from '~src/blocks/userProvider'
import { ErrorBoundaryUncaught } from '~src/pages/errorBoundary'
import './telemetry'
import config from './utils/config'

export function renderPaper(root: ReactNode) {
  const emotionCache = createCache({ key: 'x', stylisPlugins: [] })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  })

  // this prevents react-query devtools from being included when running cypress tests locally
  // this will be important if we do visual snapshotting
  const includeRQDevTools = config.meta.where === 'local'

  const reactRoot = createRoot(document.getElementById('root'))

  reactRoot.render(
    <QueryClientProvider client={queryClient}>
      {includeRQDevTools && (
        <ReactQueryDevtools
          buttonPosition="bottom-right"
          initialIsOpen={false}
          position="bottom"
        />
      )}
      <HelmetProvider>
        <EmotionCacheProvider value={emotionCache}>
          <ChakraProvider theme={chakraTheme}>
            <Global styles={globalStyles} />
            <CSSReset />
            <OverlayProvider id="overlay-provider">
              <ErrorBoundaryUncaught>
                <UserProvider>{root}</UserProvider>
              </ErrorBoundaryUncaught>
            </OverlayProvider>
          </ChakraProvider>
        </EmotionCacheProvider>
      </HelmetProvider>
    </QueryClientProvider>
  )
}
