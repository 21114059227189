import { Grid, Icon, List, ListIcon, ListItem } from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import type { LoadStatus } from '@paper/api-specs/src/specs/spec.loader'
import {
  IcoCalendar,
  IcoCheckmark,
  IcoClock,
  IcoDiscountIlluminate,
  IcoExclamationTriangle,
  IcoExternalSIS,
  IcoLoading,
  IcoX,
} from '@paper/icons'
import { useLink } from '@paper/route'
import { RunStatus } from '@paper/schema'
import { ENDASH, timeInLocal } from '@paper/utils'
import { format as formatDate } from 'date-fns'
import { ReactNode } from 'react'
import {
  BLink,
  Hl,
  HStack,
  TextStack,
  Txt,
  VSep,
  VStack,
} from '~src/components'
import { LoadingDots, LOADING_DOTS } from '~src/components/status'
import { useApiQuery } from '~src/data/useApiQuery'
import { Routes } from '~src/routelist'
import { formatPastVsNow, formatUnits } from '~src/utils/messages'

type LoaderMetadataModuleProps = {}

const useLoadmetaData = () => {
  return useApiQuery({
    apiSpec: APIs['loadmeta.get'],
    queryVars: { body: {} },
    queryFn: async ({ plainFetch }) => {
      let data = await plainFetch()

      const zonedCron = data.loadSched.map((item) =>
        formatDate(timeInLocal(item.tz, item.hour).toDate(), 'p')
      )

      return { ...data, zonedCron }
    },
    useQueryProps: { staleTime: 300_000 },
  })
}

export function LoaderMetadataModule(props: LoaderMetadataModuleProps) {
  const qResult = useLoadmetaData()

  if (!qResult.isSuccess) {
    // todo: handle errors etc.
    return (
      <VStack alignSelf="stretch">
        <LoadingDots />
      </VStack>
    )
  }

  const { jobs, loadYear, zonedCron } = qResult.data

  let doDate = (d: string) => d && formatDate(new Date(d), 'PP')
  let doVsNow = (time: number) => time && formatPastVsNow(time)

  // todo: this is a mess
  type SlotProps = Pick<
    LoaderMetadataSlots['runs'][0],
    'icon' | 'top' | 'bottom'
  > & { loadStatus: LoadStatus }
  let doSlot = (slotProps: SlotProps): LoaderMetadataSlots['runs']['0'] => {
    const { loadStatus, ...rest } = slotProps
    const status = loadStatus.lastRun?.status ?? 'never'
    const lastSuccess = status !== 'success' && doVsNow(loadStatus.lastSuccess)
    return {
      ...rest,
      status,
      date: doVsNow(loadStatus.lastRun?.runStart),
      issueCount: loadStatus.issueCount,
      lastSuccess,
    }
  }

  let slots: LoaderMetadataSlots = {
    loadFrom: doDate(loadYear?.firstDay),
    loadTo: doDate(loadYear?.lastDay),
    runs: [
      doSlot({
        icon: IcoExternalSIS,
        top: 'Enrollments',
        bottom: 'PowerSchool',
        loadStatus: jobs.enrollments,
      }),
      doSlot({
        icon: IcoDiscountIlluminate,
        top: 'Assessment scores',
        bottom: 'Illuminate',
        loadStatus: jobs.scores,
      }),
    ],
    yearCode: loadYear?.code,
    zonedCron: zonedCron ?? [LOADING_DOTS],
  }

  return <LoaderMetadataStuff {...props} slots={slots} />
}

/** placeholder values so it doesn't jump */
type LoaderMetadataSlots = {
  loadFrom: string
  loadTo: string
  runs: {
    icon: any
    top: string
    bottom: string
    status: RunStatus | 'never'
    date: string
    lastSuccess?: string
    issueCount: number
  }[]
  yearCode: string
  zonedCron: string[]
}

type LoaderMetadataStuffProps = LoaderMetadataModuleProps & {
  slots: LoaderMetadataSlots
}

export function LoaderMetadataStuff(props: LoaderMetadataStuffProps) {
  const { loadFrom, loadTo, runs, yearCode, zonedCron } = props.slots

  const whenItems = yearCode ? (
    <>
      <ListItem>
        <Hl>{yearCode}</Hl> External data
      </ListItem>
      <ListItem>
        <ListIcon as={IcoClock} />
        <HStack display="inline-flex" flexWrap="wrap" gap={1} maxWidth="200px">
          <Txt>Loaded daily</Txt>
          {zonedCron.map((time, idx) => (
            <Hl key={idx} py={0}>
              {time}
            </Hl>
          ))}
        </HStack>
      </ListItem>
      <ListItem>
        <ListIcon as={IcoCalendar} />
        From <Hl>{loadFrom}</Hl> to <Hl>{loadTo}</Hl>
      </ListItem>
    </>
  ) : (
    <>
      <ListItem>External data</ListItem>
      <ListItem>
        <ListIcon as={IcoCalendar} />
        You're currently in between school years
      </ListItem>
    </>
  )

  return (
    <HStack alignItems="center" alignSelf="stretch" gap={6} py={5}>
      <List flexShrink={0} fontSize="xs" spacing={3}>
        {whenItems}
      </List>
      <VSep />
      <Grid
        alignItems="center"
        flexShrink={0}
        fontSize="sm"
        gridColumnGap={8}
        gridRowGap={3}
        gridTemplateColumns="auto auto"
        justifyItems="start"
      >
        {runs.map((run, idx) => (
          <JobRun key={idx} run={run} />
        ))}
      </Grid>
    </HStack>
  )
}

function JobRun(props: { run: LoaderMetadataSlots['runs'][number] }) {
  const { run } = props

  let status: ReactNode

  const linkProps = useLink(Routes.setup_issues.mergeAction({}))

  if (run.status === 'success') {
    status = (
      <>
        <HStack gap={2} px={2}>
          <Icon as={IcoCheckmark} color="green.500" />
          <Txt>{run.date}</Txt>
        </HStack>
        {run.issueCount > 0 && (
          <BLink
            {...linkProps}
            justifyContent="start"
            leftIcon={<Icon as={IcoExclamationTriangle} color="orange.300" />}
            variant="ghost"
            size="xs"
          >
            {formatUnits(run.issueCount, 'issue')}
          </BLink>
        )}
      </>
    )
  } else if (run.status === 'fail' || run.status === 'locked') {
    const color = run.status === 'fail' ? 'red.500' : 'yellow.500'
    const icon = run.status === 'fail' ? IcoX : IcoLoading

    status = (
      <>
        <HStack gap={2}>
          <Icon as={icon} color={color} />
          <Txt>{run.date}</Txt>
        </HStack>
        <Txt fontStyle="italic">Last success: {run.lastSuccess ?? 'never'}</Txt>
      </>
    )
  } else if (run.status === 'never') {
    status = (
      <HStack fontStyle="italic" gap={2}>
        {ENDASH}
      </HStack>
    )
  }

  return (
    <>
      <HStack gap={4}>
        <Icon as={run.icon} />
        <TextStack display="inline-flex">
          <TextStack.Top>{run.top}</TextStack.Top>
          <TextStack.Bottom fontStyle="italic" variant="compact">
            {run.bottom}
          </TextStack.Bottom>
        </TextStack>
      </HStack>
      <VStack alignItems="stretch" fontSize="xs">
        {status}
      </VStack>
    </>
  )
}
