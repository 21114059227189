import {
  Box,
  Button,
  Grid,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { GRAY_BAR } from '@paper/styles'
import { ReactNode, useMemo } from 'react'
import { BaseHeader, CopyButton, HStack, Txt } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { useApiQuery } from '~src/data/useApiQuery'
import { RD_SW_JumpToStd } from '~src/routelist'
import { DarkMode, LightMode } from '~src/utils/forceMode'
import { NO_STD } from '~src/utils/messages'
import { useWindowSize } from '~src/utils/useMeasure'
import { useScrollIntoView2 } from '~src/utils/useScroll'
import { useQStdContext } from './qStdProvider'

type StdPopoverProps = {
  trigger: ReactNode
} & Pick<PopoverProps, 'gutter' | 'placement'>

// todo: chakra types appear to be wrong
let _PopoverTrigger = PopoverTrigger as any

export function StdPopover(props: StdPopoverProps) {
  const { gutter, placement, trigger } = props
  const popoverProps: PopoverProps = {
    gutter,
    isLazy: true,
    placement,
    preventOverflow: true,
  }

  // todo: popover doesn't seem to adjust height based on window size...
  const { height, width } = useWindowSize()
  const maxHeight = `${height - BaseHeader.Height - 40}px`
  const maxWidth = `${width - 110}px`
  return (
    <DarkMode>
      <Popover {...popoverProps}>
        <LightMode>
          <_PopoverTrigger>{trigger}</_PopoverTrigger>
        </LightMode>
        <Portal>
          <PopoverContent
            color="white"
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            overflow="auto"
            width="1000px"
          >
            <PopoverBody display="flex" justifyContent="center">
              <StdDetails />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </DarkMode>
  )
}

const opacitizer = (isSelected: boolean) => (isSelected ? null : 0.4)

type StdTooltipProps = {}

function StdDetails(props: StdTooltipProps) {
  // todo: very unpolished, sizing/scroll-to etc.

  const { dispatchStay } = useRouter<RD_SW_JumpToStd>()
  const { qDigest, stdDigest } = useQStdContext()
  const stdAggs = stdDigest.success?.items

  const domRef = useScrollIntoView2(stdDigest.success?.selectedItem?.std)

  // todo: probably add to digest
  const commonPrefix = useMemo(() => {
    if (!stdAggs?.length) {
      return ''
    }
    let first = stdAggs[0].std
    let commonPrefix = ''
    out: for (let i = 0; i < first.length; i++) {
      for (let j = 1; j < stdAggs.length; j++) {
        if (first[i] !== stdAggs[j].std[i]) {
          break out
        }
      }
      commonPrefix += first[i]
    }
    return commonPrefix
  }, [stdAggs])

  // todo: might be better to do this lookup when returning the xpackets?
  const qResult = useApiQuery({
    apiSpec: APIs['std.list'],
    queryFn: async ({ plainFetch }) => {
      const result = await plainFetch()
      const map = new Map(result.map((p) => [p.std, p.desc]))
      return map
    },
    queryVars: { body: { stds: stdAggs?.map((p) => p.label) } },
    useQueryProps: { enabled: !!stdAggs?.length },
  })

  let body: ReactNode

  if (!stdDigest.success || !qResult.isSuccess) {
    body = <LoadingDots />
  } else {
    const { items, selectedItem } = stdDigest.success
    const descMap = qResult.data

    body = (
      <Grid
        alignItems="center"
        columnGap={4}
        gridAutoRows="auto"
        gridTemplateColumns="minmax(max-content,auto) minmax(max-content,auto) minmax(400px,1fr)"
        justifyItems="start"
        px={2}
        py={4}
        rowGap={3}
        userSelect="none"
        width="100%"
      >
        {items.map((stdAgg, idx) => {
          const isSelected = stdAgg === selectedItem

          return (
            <Box display="contents" key={idx}>
              {stdAgg.std === NO_STD ? (
                <Box />
              ) : (
                <CopyButton
                  aria-label="Copy full standard"
                  size="xs"
                  value={stdAgg.std}
                  variant="ghost"
                />
              )}
              <Button
                fontFamily="mono"
                fontSize="lg"
                fontWeight={isSelected ? 'bold' : null}
                justifyContent="start"
                onClick={() =>
                  dispatchStay({ qId: stdAgg.qs[0].id, std: stdAgg.std })
                }
                opacity={opacitizer(isSelected)}
                ref={isSelected ? domRef : null}
                size="sm"
                variant="ghost"
                width="unset"
              >
                {stdAgg.label.slice(commonPrefix.length)}
              </Button>

              <HStack fontSize="sm">
                {stdAgg.qs.map((q, idx) => {
                  const bg = gradHack(GRAY_BAR, 'rgba(0,0,0,.2)', q.pct)
                  const isSelected = q === qDigest.success?.selectedItem
                  return (
                    <Button
                      gap={1}
                      key={q.id}
                      onClick={() =>
                        dispatchStay({ qId: q.id, std: stdAgg.std })
                      }
                      opacity={opacitizer(isSelected)}
                      size="sm"
                      variant="ghost"
                    >
                      <Txt as="span">{q.label}</Txt>
                      <Box bg={bg} height="20px" width="3px" />
                    </Button>
                  )
                })}
              </HStack>
              {isSelected && stdAgg.label !== NO_STD && (
                <Txt
                  gridColumnStart="2"
                  gridColumnEnd="-1"
                  height={isSelected ? 'auto' : 0}
                  fontSize="sm"
                  ml={3}
                  mb={3}
                >
                  {descMap.get(stdAgg.label)}
                </Txt>
              )}
            </Box>
          )
        })}
      </Grid>
    )
  }
  return body
}

/**
 * @deprecated
 * todo: probably not this
 * `correct` appears at the bottom
 */
const gradHack = (right: string, wrong: string, pct: number) => {
  const guts = [
    [wrong, 0],
    [wrong, 100 - pct],
    [right, 100 - pct, right, 100],
  ]
    .map(([color, pct]) => `${color} ${pct}%`)
    .join(', ')
  return `linear-gradient(${guts})`
}
