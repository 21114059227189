import { Button, ButtonProps } from '@chakra-ui/react'
import { IcoHelp, IcoReportIssue } from '@paper/icons'
import { BLink } from '~src/components'
import { NavListSection } from '~src/routelist/navLinks'
import { getSupportMailTo } from '~src/utils/contactSupport'
import { useHelpLink } from '~src/utils/useHelpLink'
import { getMenuItemExternalLinkProps, MenuItem } from './menu'
import { NavLink } from './menu/navLink'

const ReportIssueEmail = `itsupport@uncommonschools.org`
const reportIssueProps = { email: ReportIssueEmail, subject: 'User Issue' }

function MIReportIssue() {
  // todo: probably rollbar here too for its instrumentation?
  return (
    <MenuItem
      icon={<IcoReportIssue />}
      onSelect={() => {
        window.location.href = getSupportMailTo(reportIssueProps)
      }}
    >
      Report an issue
    </MenuItem>
  )
}

type MIHelpProps = { message?: string }

function MIHelp(props: MIHelpProps) {
  let href = useHelpLink()
  return (
    <MenuItem {...getMenuItemExternalLinkProps(href)} icon={<IcoHelp />}>
      {props.message || 'Help'}
    </MenuItem>
  )
}

/**
 * MenuItems for Help and Report Issue
 * todo: maybe make this a section?
 */
export function MISupport() {
  return (
    <>
      <MIHelp />
      <MIReportIssue />
    </>
  )
}

/**
 * Button versions of support menu items
 */
export function SupportButtons() {
  const sharedProps = { variant: 'outline' } satisfies ButtonProps
  const href = useHelpLink()
  return (
    <>
      <BLink
        {...sharedProps}
        href={href}
        isExternal={true}
        leftIcon={<IcoHelp />}
      >
        Help
      </BLink>
      <Button
        {...sharedProps}
        leftIcon={<IcoReportIssue />}
        onClick={() => {
          window.location.href = getSupportMailTo(reportIssueProps)
        }}
        variant="outline"
      >
        Report an issue
      </Button>
    </>
  )
}

/**
 * Support links navigation menu section
 */
export function SupportNavSection() {
  let href = useHelpLink()
  // todo: share code with MenuItem
  return (
    <NavListSection title="Support">
      <NavLink href={href} icon={<IcoHelp />} isExternal={true}>
        Help
      </NavLink>
      <NavLink
        as="button" // todo: w/o href you can't keyboard navigate, but with href the link navigates
        icon={<IcoReportIssue />}
        onSelect={(event) => {
          window.location.href = getSupportMailTo(reportIssueProps)
        }}
        width="100%"
      >
        Report an issue
      </NavLink>
    </NavListSection>
  )
}
