import {
  now,
  parseAbsolute,
  Time,
  toLocalTimeZone,
} from '@internationalized/date'

/**
 * Returns `isoDate`s `Time` in `timezone`
 */
export const timeInZone = (isoDate: string, timezone: string) => {
  const zonedNow = parseAbsolute(isoDate, timezone)
  const { hour, minute, second, millisecond } = zonedNow
  return new Time(hour, minute, second, millisecond)
}

/**
 * Returns `time` in `timezone` in local timezone
 */
export const timeInLocal = (
  timezone: string,
  hour: number,
  minute = 0,
  second = 0,
  millisecond = 0
) => {
  // Get ZonedDateTime object of `time` in `timezone`
  // todo: is there a cleaner way?, the ZDT constructor seems to require knowing the offset of `timezone`
  let zdt = now(timezone)
  zdt = zdt.set({ hour, minute, second, millisecond })
  return toLocalTimeZone(zdt)
}
