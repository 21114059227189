import { Box } from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { IcoLink } from '@paper/icons'
import { useLink, useRouter } from '@paper/route'
import { NotFoundError } from '~src/blocks/errors'
import { ImageViewer } from '~src/blocks/imageViewer'
import { IcoQ } from '~src/blocks/swMenu/swMenuIcons'
import { AppTitle, BaseHeader, BLink, HStack, Txt } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { useDirectoryData } from '~src/data/data-directory'
import { useApiQuery } from '~src/data/useApiQuery'
import { RD_PinGrid, Routes } from '~src/routelist'
import { SchoolYearPicker, useSchoolYearContext } from '~src/schoolYearAirlock'
import { DarkMode } from '~src/utils/forceMode'
import {
  PinGridAirlock,
  PinGridDigest,
  usePinGridContext,
} from './pinGridAirlock'
import { StickyGrid } from './stickGrid2'

export function PinGridPage() {
  const { routeData } = useRouter<RD_PinGrid>()
  const { contentId } = routeData
  const { syId } = useSchoolYearContext()

  const qResult = useApiQuery({
    apiSpec: APIs['pin.list'],
    queryVars: { body: { contentId, syId } },
    queryFn: async ({ plainFetch }) => {
      if (!contentId) {
        // 404 if no contentId
        throw new NotFoundError({ thing: 'packet', value: '???' })
      }
      let result = await plainFetch()
      return result
    },
    // todo: otherwise this gets eaten..., and FullPageLoading isn't equipped to handle this kind of error yet...
    useQueryProps: { throwOnError: true },
  })

  // todo: need to wait for this too, so we have teacher names
  const dirQResult = useDirectoryData()

  // todo:!!!!
  const xxx = {
    // `qResult.isPending` is now true for disabled queries with no data!
    isPending: qResult.isPending || dirQResult.isPending,
  }

  return (
    <FullPageLoading qResult={xxx}>
      <PinGridAirlock baseData={qResult.data}>
        <CrossNetworkGrid />
      </PinGridAirlock>
    </FullPageLoading>
  )
}

// todo: going to start by copy/pasting TimeGrid
function CrossNetworkGrid() {
  const { digest } = usePinGridContext()
  const { selectedZ } = digest

  return (
    <DarkMode>
      <SchoolYearPicker zIndex={1} />
      <AppTitle title="Pins" />
      <Box
        alignItems="stretch"
        display="grid"
        // todo: this sets the Image column such that 1 page fits with horizontal a padding
        gridTemplateColumns={`1fr calc(((100vh - 64px) / 11 * 8.5) + 48px)`}
        gridTemplateRows="100vh"
        height="100vh"
        justifyItems="stretch"
        overflow="hidden"
        position="fixed"
        width="100vw"
      >
        <StickyGrid digest={digest} />
        <Box
          bg="#4c4c4c"
          display="grid"
          gridRowGap={1} // why isn't the boxShadow on top of ImageViewer?
          gridTemplateColumns="auto"
          gridTemplateRows={`${BaseHeader.Height}px 1fr`}
          justifyItems="center"
          zIndex={3} // todo: coordinate!
        >
          <HStack
            // todo: consolidate styles!
            boxShadow="rgb(0 0 0 / 8%) 0px 3px 7px 0px"
            justifySelf="stretch"
            px={4}
          >
            {selectedZ?.xpf && <JumpToLink {...digest} />}
          </HStack>
          {selectedZ &&
            (selectedZ.xpf ? (
              <ImageViewer imageType="sw" pages={selectedZ.xpf.pages} />
            ) : (
              <Txt color="white" p={4}>
                Paper doesn't have a sample for this answer yet.
              </Txt>
            ))}
        </Box>
      </Box>
    </DarkMode>
  )
}

function JumpToLink(digest: PinGridDigest) {
  const { selectedXY, selectedZ } = digest
  const { curriculumId, packetId, qId, teacherId } = selectedXY
  const linkProps = useLink(
    Routes.sw_jumpToQ.navigateAction({
      curriculumId,
      packetId,
      teacherId,
      qId,
      xpacketId: selectedZ.xpf.id,
      f_ans: selectedZ.aStr,
    })
  )
  return (
    <BLink
      {...linkProps}
      isExternal={true}
      leftIcon={<IcoQ />}
      rightIcon={<IcoLink />}
      variant="ghost"
    >
      See all for this teacher
    </BLink>
  )
}
