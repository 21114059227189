import { isPlainObject } from 'lodash'
import _groupBy from 'lodash/groupBy'

/**
 * `Object.keys` with types
 */
export function objectKeys<O extends object>(o: O) {
  return Object.keys(o) as any as (keyof O)[]
}

type OKey = string | number | symbol

/**
 * `Object.entries` with types
 */
export function entries<T, K extends OKey>(o: Record<K, T>): [K, T][] {
  return Object.entries(o) as [K, T][]
}

/**
 * `Object.entries(o).map(cb)` with types
 */
export function mapEntries<K extends OKey, T, U>(
  o: Record<K, T>,
  cb: (item: [K, T], index: number) => U
): U[] {
  return entries(o).map(cb)
}

/**
 * `Object.fromEntries(arr.map(cb))` with types
 */
export function objectify<T, K extends string | number, V>(
  arr: T[],
  cb: (item: T, index: number) => [K, V]
): Record<K, V> {
  return Object.fromEntries(arr.map(cb)) as Record<K, V>
}

/**
 * `lodash.groupBy` with types
 */
export function groupBy<T, K extends OKey>(
  array: T[],
  iteratee: (item: T) => K
): Record<K, T[]> {
  return _groupBy(array, iteratee) as Record<K, T[]>
}

/**
 * @deprecated todo: library/this is probably duplicated in diff logic
 * @example
 * objectToFlatEntries({
 *  a: 1,
 *  b: { x: 2, y: 3 }
 * })
 * // [{ key: 'a', value: 1}, { key: 'b.x', value: 2}, { key: 'b.y', value: 3}]
 */
export function objectToFlatEntries(
  obj: object,
  lineage = []
): { key: string; value: any }[] {
  if (!obj) {
    return null
  }

  return Object.entries(obj).flatMap(([key, value]) => {
    const thisPath = [...lineage, key]
    if (value && (isPlainObject(value) || Array.isArray(value))) {
      return objectToFlatEntries(value, thisPath)
    } else {
      return { key: thisPath.join('.'), value }
    }
  })
}
