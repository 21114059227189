import { CM } from '../mongo/collections'

export type Invalid<T> = {
  issues: { path: string; value: any; message: string }[]
  fullItem: T
}

export type BulkSummary = {
  ok: number
  writeErrors?: any[]
  writeConcernError?: any
  nInserted: number
  nUpserted: number
  nMatched: number
  nModified: number
  nRemoved: number
}

export type LoadJobType = 'enrollments' | 'scores' | 'stds'

export type RunStatus = 'success' | 'fail' | 'locked' | 'not-run-locked'

export interface LoadMeta {
  bulkResult: {
    [key in keyof CM]?: BulkSummary
  }
  counts: {
    [key in keyof CM]?: {
      valid: number
      error: number
      removed: number
      sizeChange: number
    }
  }
  error?: any
  /** todo: this type might not be right for every collection... */
  errors: {
    [key in keyof CM]?: Invalid<CM[key]>[]
  }
  jobType: LoadJobType
  runDurationSec: number
  runEnd: number
  /** `[jobType].[runStart]` */
  runId: string
  runStart: number
  sched?: LoadSchedule
  status: RunStatus
  syId: number
}

export type LoadSchedule = {
  hour: number
  tz: string
  workflowId: string
}
