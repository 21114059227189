import { School, Teacher, XpageSW } from '..'
import { LastUpdate } from './packetmeta'

/** ids for a unique pin slot */
export type PinShared = {
  _id: string // cellId + aStr
  cellId: string // cellId so we can $lookup on a single field
  contentId: string // cross-network lookup
  curriculumId: string // convenient link to jump-to-q
  // for ids
  packetId: string
  qId: string
  syId: number // should be `term`, but can deal with that later
  teacherId: string
}

/**
 * Storing pins separately for now as it makes updates a bit simpler
 * todo: does it? i have to match on like 12 fields...
 */
export type Pin = PinShared & {
  _update: LastUpdate
  aStr: string
  pinnedId: string
}

/////////////////
// QCells
/////////////////
type BaseQ = {
  qId: string
  qIndex: number
  qLabel: string
  iis: number[]
}

type PinBaseAnswer = {
  aStr: string
  correct: boolean
}

type PinAnswerDb = PinBaseAnswer & {
  count: number
  exampleId: string
}

export type PinAnswer = PinAnswerDb & {
  pinnedId?: string
  xpf?: XpacketFragment
}

type Cellify<T> = PinShared & BaseQ & { count: number } & T

export type PinQCellDb = Cellify<{
  _illuminate: string // todo: not necessary, but useful for debugging
  answers: Record<string, PinAnswerDb>
}>

// maybe worth slimming this down further at some point
// don't currently need contentId, year
export type PinQCell = Cellify<{ answers: Record<string, PinAnswer> }>

export type QAxisItem = BaseQ & {
  answers: Record<string, PinBaseAnswer>
  id: string
}

/**
 * This is actually Teacher+Packet (which implictly include curriculum)
 * For now, I'm mostly expecting this to be unique to teacher, but we can adjust
 */
export type TeaAxisItem = {
  id: string
  curriculumId: string
  fullName: string
  packetId: string
  school: School
  teacher: Teacher
  teacherId: string
}

export type XpacketFragment = { id: string; pages: XpageSW[] }
