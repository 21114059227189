import { ResultOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { useStateAndRef } from '@paper/route/src/utils'
import { PinAnswer, PinQCell, QAxisItem, TeaAxisItem } from '@paper/schema'
import {
  getFullName,
  setDefault,
  setDefaultMap,
  sortKeys,
  sortNumeric,
} from '@paper/utils'
import { debounce, mapValues, merge, orderBy, times } from 'lodash'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { NotFoundError } from '~src/blocks/errors'
import { useDirectoryData } from '~src/data/data-directory'
import { RD_PinGrid } from '~src/routelist'

type Axis<T> = { empty?: boolean; items: T[]; selectedIndex: number }

type SparseAxis = { x: number; y: number; z: string }[]
export type SparseAxisLookup = Map<string, { [-1]: number; [1]: number }>

export type GridDigest<X, Y, T, Z = never> = {
  empty: boolean
  items2d: T[][]
  selectItem(item: T, z: string): void
  selectedXY?: T
  selectedZ?: Z
  selectedZKey?: string
  xAxis: Axis<X>
  yAxis: Axis<Y>
}

export type PinGridDigest = GridDigest<
  QAxisItem,
  TeaAxisItem,
  PinQCell,
  PinAnswer
> & { pinAxis: SparseAxis; pinAxisLookup: SparseAxisLookup }

type PinGridAirlockContext = { digest: PinGridDigest }
const PinGridAirlockContext = createContext<PinGridAirlockContext>(null)
export const usePinGridContext = () => useContext(PinGridAirlockContext)

type PinGridAirlockProps = {
  baseData: ResultOf<'pin.list'>
  children: ReactNode
}

const xyzToString = ({ x, y, z }: SparseAxis[0]) => `${x},${y},${z}`

export const getTeaAxisId = (arg: { packetId: string; teacherId: string }) =>
  arg ? `${arg.packetId}.${arg.teacherId}` : null

// very copy-paste!
export function PinGridAirlock({ baseData, children }: PinGridAirlockProps) {
  // console.time('PinGridAirlock')
  let cells = baseData

  if (!cells) {
    // todo: we don't pass the contentId in here
    throw new NotFoundError({ thing: 'packet', value: '???' })
  }

  const dirData = useDirectoryData().data
  const { dispatchStay, routeData } = useRouter<RD_PinGrid>()

  const { xAxisItems, yAxisItems, items2d, pinAxis, pinAxisLookup } =
    useMemo(() => {
      const qMap = new Map<string, QAxisItem>()
      const teaMap = new Map<string, TeaAxisItem>()

      // Pull out axes - possible we should pre-do some of this...
      for (let cell of cells) {
        ///////////
        // QAxis
        const { curriculumId, iis, packetId, qId, qIndex, qLabel, teacherId } =
          cell

        // this is copy/pasty with illLoad...maybe worth saving these separately
        const qItem = setDefaultMap(qMap, cell.qId, {
          answers: {},
          id: qId,
          iis,
          qId,
          qIndex,
          qLabel,
        })

        // merge in base answer info
        merge(
          qItem.answers,
          mapValues(cell.answers, ({ aStr, correct }) => ({ aStr, correct }))
        )

        ///////////
        // TeaAxis
        const teaAxisId = getTeaAxisId(cell) // not actually a teacher axis...
        setDefaultMap(teaMap, teaAxisId, (): TeaAxisItem => {
          const teacherItem = dirData?.teacher.map.get(teacherId)
          // teachers could have multiple schools, but ignoring that for now
          const schoolId = [...(teacherItem?.schoolIds ?? [])][0]
          const school = dirData?.school.map.get(schoolId)?.item
          const teacher = teacherItem?.item
          const fullName = getFullName(teacher)
          return {
            curriculumId,
            id: teaAxisId,
            fullName,
            packetId,
            school,
            teacher,
            teacherId,
          }
        })
      }

      // sort answers within qMap since we don't know what order they'll come in
      for (let item of qMap.values()) {
        item.answers = sortKeys(item.answers)
      }

      // add 0 answer entries to cells (todo: maybe do this in the db instead...)
      for (let cell of cells) {
        const qMeta = qMap.get(cell.qId)
        Object.values(qMeta.answers).forEach(({ aStr, correct }) => {
          setDefault(cell.answers, aStr, {
            aStr,
            count: 0,
            correct,
            exampleId: null,
            xpf: null,
          })
        })
        cell.answers = sortKeys(cell.answers)
      }

      // finish and sort axes
      let xAxisItems = [...qMap.values()]
      sortNumeric(xAxisItems, (p) => p.qLabel)

      let yAxisItems = orderBy(
        [...teaMap.values()],
        [(p) => p.school?.name, (p) => p.fullName]
      )

      ////////////////////////
      // do items2d here too?
      // get index maps
      let xAxisMap = new Map(xAxisItems.map((item, idx) => [item.id, idx]))
      let yAxisMap = new Map(
        yAxisItems.map((item) => [
          item.id,
          times(xAxisItems.length, () => null),
        ])
      )

      // make rows in yAxisMap
      cells.map((cell) => {
        const xIdx = xAxisMap.get(cell.qId)
        // Only splice if xIdx is defined
        if (xIdx >= 0) {
          yAxisMap.get(getTeaAxisId(cell))?.splice(xIdx, 1, cell)
        }
      })

      // And put columns in 2d
      let items2d: PinQCell[][] = []
      for (let y = 0; y < yAxisItems.length; y++) {
        items2d[y] = yAxisMap.get(yAxisItems[y].id)
      }

      // Put together a "pin axis" so it's easy to jump to pins
      let pinAxis: SparseAxis = []
      let pinAxisLookup: SparseAxisLookup = new Map()
      for (let y = 0; y < yAxisItems.length; y++) {
        for (let x = 0; x < xAxisItems.length; x++) {
          let candidate = items2d[y]?.[x]
          if (candidate) {
            for (let z in candidate.answers) {
              let xyz = { x, y, z }
              if (candidate.answers[z].pinnedId) {
                pinAxis.push(xyz)
                pinAxisLookup.set(xyzToString(xyz), {
                  [-1]: pinAxis.length - 1,
                  1: pinAxis.length - 1,
                })
              } else {
                // we need to be able to lookup any entry, not just pinned!
                pinAxisLookup.set(xyzToString(xyz), {
                  [-1]: pinAxis.length, // next one if we're going backwards
                  1: pinAxis.length - 1, // prev one if we're going forwards
                })
              }
            }
          }
        }
      }
      return { items2d, pinAxis, pinAxisLookup, xAxisItems, yAxisItems }
    }, [cells, dirData])

  // lookup indexes
  const selectedXIndex = xAxisItems.findIndex((p) => p.id === routeData.qId)
  const selectedYIndex = yAxisItems.findIndex((p) => p.id === routeData.teaId)

  // and selected items
  const selectedXY = items2d[selectedYIndex]?.[selectedXIndex]
  const selectedZ = selectedXY?.answers[routeData.aStr]

  const digest: PinGridDigest = {
    empty: cells.length === 0,
    items2d,
    pinAxis,
    pinAxisLookup,
    selectItem: useCallback((item, z) => {
      // console.log('selectItem', item, z)
      dispatchStay({ aStr: z, qId: item?.qId, teaId: getTeaAxisId(item) })
    }, []),
    selectedXY,
    selectedZ,
    selectedZKey: routeData.aStr,
    xAxis: {
      empty: xAxisItems.length === 0,
      items: xAxisItems,
      selectedIndex: selectedXIndex,
    },
    yAxis: {
      empty: yAxisItems.length === 0,
      items: yAxisItems,
      selectedIndex: selectedYIndex,
    },
  }

  const ctx = { digest }

  // console.timeEnd('PinGridAirlock')

  return (
    <PinGridAirlockContext.Provider value={ctx}>
      {children}
    </PinGridAirlockContext.Provider>
  )
}

/**
 * Walks `candidate.answers` from `z` in `dir` and returns next z
 */
const walkZ = (candidate: PinQCell, dir: -1 | 1, z?: string) => {
  // todo: this would be simpler if answers were still sparse
  // todo: may be worth pre-calculating..
  // filter keys to those with counts
  const aKeys = Object.entries(candidate.answers)
    .filter(([key, value]) => value?.count)
    .map(([key]) => key)

  if (z) {
    // next starting at direction
    return aKeys[aKeys.indexOf(z) + dir]
  } else {
    // else return first or last
    return aKeys.at(dir === -1 ? -1 : 0)
  }
}

type XYZ = [x: number, y: number, z: string]
/**
 * Avoid landing on missing xpackets
 * todo: probably integrate with airlock...
 */
export function useXYZCursor(digest: PinGridDigest) {
  const {
    items2d,
    pinAxis,
    pinAxisLookup,
    selectItem,
    selectedZKey,
    xAxis,
    yAxis,
  } = digest
  const [[x, y, z], setXyz, xyzRef] = useStateAndRef<XYZ>([
    xAxis.selectedIndex,
    yAxis.selectedIndex,
    selectedZKey,
  ])

  const move = useMemo(() => {
    console.log('useXYCursor->memo', xyzRef.current)

    const debouncedSelect = debounce(
      (x: number, y: number, z: string) => {
        selectItem(items2d[y][x], z)
      },
      220,
      { leading: false, trailing: true }
    )

    const to = (x: number, y: number, z: string) => {
      if (
        x >= 0 &&
        y >= 0 &&
        x < xAxis.items.length &&
        y < yAxis.items.length
      ) {
        // Set cursor immediately
        setXyz([x, y, z])
        // Debounce the more expensive select
        debouncedSelect(x, y, z)
      }
    }

    const nextHoriz = (axis: 'x', dir: -1 | 1) => () => {
      const [x, y, z] = xyzRef.current

      const isX = axis === 'x'
      const current = isX ? x : y
      const searchEnd = isX ? xAxis.items.length : yAxis.items.length

      // try moving z
      const nextZ = walkZ(items2d[y][x], dir, z)
      if (nextZ) {
        to(x, y, nextZ)
        return
      }
      // do x
      else {
        for (let i = current + dir; i >= 0 && i < searchEnd; i += dir) {
          const candidate = isX ? items2d[y][i] : items2d[i][x]
          if (candidate) {
            // pick first/final z depending on direction
            let nextZ = walkZ(candidate, dir)
            isX ? to(i, y, nextZ) : to(x, i, z)
            return
          }
        }
      }
    }

    const next = (axis: 'x' | 'y', dir: -1 | 1) => () => {
      // todo: this is probably worse than having separate horiz/vert...
      const [x, y, z] = xyzRef.current

      const isX = axis === 'x'
      const current = isX ? x : y
      const searchEnd = isX ? xAxis.items.length : yAxis.items.length

      for (let i = current + dir; i >= 0 && i < searchEnd; i += dir) {
        const candidate = isX ? items2d[y][i] : items2d[i][x]
        // answers aren't sparse, so check for count
        if (!candidate?.answers[z]?.count) {
          continue
        } else {
          isX ? to(i, y, z) : to(x, i, z)
          return
        }
      }
    }

    const nextPin = (dir: -1 | 1) => () => {
      const [x, y, z] = xyzRef.current
      let entry = pinAxisLookup.get(xyzToString({ x, y, z }))
      let curIdx = entry?.[dir]
      let nextIdx =
        curIdx >= 0 ? curIdx + dir : dir === 1 ? 0 : pinAxis.length - 1
      let candidate = pinAxis[nextIdx]
      if (candidate) {
        to(candidate.x, candidate.y, candidate.z)
      }
    }

    return {
      down: next('y', 1),
      left: nextHoriz('x', -1),
      nextPin: nextPin(1),
      prevPin: nextPin(-1),
      right: nextHoriz('x', 1),
      to,
      up: next('y', -1),
    }
  }, [items2d, selectItem, xAxis.items, yAxis.items])

  ///////////////////////////
  // Ensure we're in bounds
  ///////////////////////////
  useEffect(() => {
    if (items2d) {
      if (
        xAxis.selectedIndex < 0 ||
        xAxis.selectedIndex !== x ||
        yAxis.selectedIndex < 0 ||
        yAxis.selectedIndex !== y
      ) {
        const newX = Math.max(0, xAxis.selectedIndex)
        const newY = Math.max(0, yAxis.selectedIndex)
        // grab a valid z
        // interesting that this doesn't walk to find a valid item...
        const candidate = items2d[newY]?.[newX]
        const newZ = candidate && Object.keys(candidate?.answers)[0]

        move.to(newX, newY, newZ)
      }
    }
  }, [items2d, xAxis, yAxis])

  return { move, x, y, z }
}
