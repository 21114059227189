import { useRouter } from '@paper/route'
import { useStateAndRef } from '@paper/route/src/utils'
import { PacketAxisItem, Student, XpacketSW } from '@paper/schema'
import { getFullName } from '@paper/utils'
import { produce, setAutoFreeze } from 'immer'
import { debounce, orderBy, sumBy, times } from 'lodash'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { ListAdapter, Success, useListCallbacks } from '~src/blocks/list'
import { useTeacherContext } from '~src/blocks/teacherAirlock'
import { AppTitle } from '~src/components'
import { laPacket, laStudent } from '~src/data/listAdapters'
import { RD_SW_Time } from '~src/routelist'
import { useDeepMemo } from '~src/utils/useMemos'
import { useAlwaysUpdateRef } from '~src/utils/useRefs'
import { TeaTimeBaseData } from './data-time'
import { ColSortContext, useColSortAirlock } from './useColSortAirlock'

// todo: for producing column counts...but probably need to put that in immer?
setAutoFreeze(false)

export type GridDigest<X, Y, T> = {
  empty: boolean
  items2d: T[][]
  selectItem(item: T): void
  selectedItem?: T
  xAxis: Success<X>
  yAxis: Success<Y>
}

export type TeaGridDigest = GridDigest<PacketAxisItem, Student, XpacketSW> &
  Pick<TeaTimeBaseData, 'packetNumberPrefixes' | 'sections' | 'stds'>

type TimeAirlockContext = {
  digest: TeaGridDigest
  xyCursor: XYCursor
} & ColSortContext
const TimeAirlockContext = createContext<TimeAirlockContext>(undefined)
export const useTeacherTimeContext = () => useContext(TimeAirlockContext)

type TimeAirlockProps = {
  baseData: TeaTimeBaseData
  children: ReactNode
}

/**
 * Teacher Time Airlock
 */
export function TimeAirlock(props: TimeAirlockProps) {
  const { routeData } = useRouter<RD_SW_Time>()
  const teacherCtx = useTeacherContext()

  // //console.time('(X) TimeAirlock crunch')
  const stdResult = useCalcFilters(
    props.baseData,
    routeData.std,
    routeData.f_sectionId
  )
  // todo: clean up this mess!
  const rowColResult = useRowColFilters(stdResult.baseData)
  const sortResult = useSort(rowColResult.baseData)
  const digest = useDigest(sortResult.baseData)
  const xyCursor = useXYCursor(digest)
  const value: TimeAirlockContext = {
    ...sortResult.colSortContext,
    digest,
    xyCursor,
  }
  // //console.timeEnd('(X) TimeAirlock crunch')

  return (
    <TimeAirlockContext.Provider value={value}>
      <AppTitle title={['Time', getFullName(teacherCtx?.teacher)]} />
      {props.children}
    </TimeAirlockContext.Provider>
  )
}

const TIMERS = {
  calcFilters: '(1) Calc filters',
  sectionFilter: '(1a) Section filter',
  packetAxisStats: '(2) packetAxis[].stats',
  colFilters: '(3a) colFilters',
  rowFilters: '(3b) rowFilters',
  sort: `(4) sort`,
  memoItems2d: '(5) memo items2d',
}

/**
 * Applies the standard filter to xpacket cells, section filter to studentAxis, and does post-filter calculations
 * Note that it doesn't directly remove rows or columns
 * * Changes with `std`, `xpackets`/`packetAxis` (base API data)
 * * Modifies `xpackets` and and adds `packetAxis[].stats`
 */
function useCalcFilters(
  baseData: TeaTimeBaseData,
  std: string,
  sectionId: string
) {
  const memoKey = [baseData, std, sectionId] as const
  return useMemo(() => {
    if (std || sectionId) {
      //console.time(TIMERS.calcFilters)
      baseData = produce(baseData, (draft) => {
        // Remove students that don't include the section
        if (sectionId) {
          //console.time(TIMERS.sectionFilter)
          // feels a bit inefficient...
          const keepSet = new Set(
            baseData.sections
              .find((p) => p.id === sectionId)
              .students.map((s) => s.id)
          )
          draft.studentAxis = draft.studentAxis.filter((p) => keepSet.has(p.id))
          draft.xpackets = draft.xpackets.filter((xp) =>
            keepSet.has(xp.student.id)
          )
          //console.timeEnd(TIMERS.sectionFilter)
        }

        if (std) {
          // Remove xpackets that don't include the std
          const { packetAxis, packetMaps } = baseData
          const packetsWithout = new Set(
            packetAxis.filter((pa) => !pa.stds.includes(std)).map((pa) => pa.id)
          )
          if (packetsWithout.size > 0) {
            draft.xpackets = draft.xpackets.filter(
              (xp) => !packetsWithout.has(xp.packetId)
            )
          }

          // Remove qs and pages that don't include the std
          draft.xpackets.forEach((xp) => {
            const { axis, stdPage, stdQ } = packetMaps.get(xp.packetId)

            // Filter pages
            const pageIdxSet = stdPage.get(std)
            if (pageIdxSet) {
              xp.pages = xp.pages.filter((_, pageIdx) =>
                pageIdxSet.has(pageIdx)
              )
            }

            // Zero-out qs
            const qIdSet = stdQ.get(std)
            xp.qs?.forEach((xq, qIdx) => {
              // xpacket doesn't have qId so lookup by index in packetAxis
              if (xq && !qIdSet.has(axis.questions[qIdx].id)) {
                xq._outOf_ = 0
                xq.pts = 0
              }
            })
          })
        }
      })
      //console.timeEnd(TIMERS.calcFilters)
    }

    // Produce column counts
    //console.time(TIMERS.packetAxisStats)
    const { packetAxis, xpackets } = baseData
    packetAxis.forEach((pa) => {
      pa.stats = {
        missing: 0,
        withScans: 0,
        withScores: 0,
      }
    })
    // todo: immer instead probably
    baseData.packetAxis = [...packetAxis]

    // todo: (?) hoist/share this
    const packetAxisMap = new Map(packetAxis.map((pa) => [pa.id, pa]))
    // todo: may also be the place to calculate the packetSortMap...

    xpackets.forEach((xpacket) => {
      const stats = packetAxisMap.get(xpacket.packetId).stats
      // If any scoring/tagging data
      if (xpacket.pts != null || xpacket.rubric || xpacket.qs) {
        stats.withScores += 1
      }
      // Missing
      if (xpacket.status === 'missing') {
        stats.missing += 1
      } else {
        // Scans
        stats.withScans += 1
      }
    })
    //console.timeEnd(TIMERS.packetAxisStats)

    return { baseData }
  }, memoKey)
}

/**
 * Applies row and column filters
 * * Changes with certain routeData, `packetAxis[].stats`, (and base API data)
 * * Filters `packetAxis` and `studentAxis`(but doesn't modify values)
 */
function useRowColFilters(baseData: TeaTimeBaseData) {
  // The useMemo api is not friendly for a bunch of filters...
  // todo: overlaps with useClientCrunch
  const { routeData } = useRouter<RD_SW_Time>()
  let { f_packet, tgf_col } = routeData
  f_packet = f_packet?.trim()
  const packetCrunchKey = useDeepMemo({ f_packet, tgf_col })

  let { packetAxis, studentAxis, xpackets } = baseData

  ////////////////////////////
  // Columns
  ////////////////////////////
  packetAxis = useMemo(() => {
    //console.time(TIMERS.colFilters)
    const packetFilters: ((pa: PacketAxisItem) => any)[] = []
    if (f_packet) {
      packetFilters.push((pa) => pa.number.startsWith(f_packet))
    }
    if (tgf_col) {
      packetFilters.push((pa) => pa.stats.withScans || pa.stats.withScores)
    }

    let result = packetAxis
    if (packetFilters.length > 0) {
      result = result.filter((pa) => {
        return packetFilters.every((filter) => filter(pa))
      })
    }
    //console.timeEnd(TIMERS.colFilters)
    return result
  }, [packetAxis, packetCrunchKey])

  ////////////////////////////
  // Rows
  ////////////////////////////
  // const tagFilterContext = usePacketTagFilterAirlock(packetAxis)
  // const { tagFilter } = tagFilterContext

  // studentAxis = useMemo(() => {
  //   //console.time(TIMERS.rowFilters)
  //   let filtered = studentAxis

  //   if (tagFilter) {
  //     // todo: probably not great that we have to iterate through all the packets...
  //     const studentSet = new Set(
  //       xpackets
  //         .filter(
  //           (xp) => xp.packetId === tagFilter.id && xp.tag === tagFilter.tag
  //         )
  //         .map((xp) => xp.student.id)
  //     )
  //     filtered = studentAxis.filter((sa) => studentSet.has(sa.id))
  //   }
  //   //console.timeEnd(TIMERS.rowFilters)
  //   return filtered
  // }, [studentAxis, xpackets])

  return {
    baseData: { ...baseData, packetAxis, studentAxis },
  }
}

/**
 * Sorts the grid
 * * Changes with `colSort`+airlock, `studentAxis`, `packetAxis`, and `xpackets`
 * * Modifies order of `studentAxis` and `packetAxis`
 */
function useSort(baseData: TeaTimeBaseData) {
  const colSortContext = useColSortAirlock(baseData.packetAxis)
  const { colSort } = colSortContext

  const memoKey = [colSort, baseData.studentAxis, baseData.xpackets] as const

  const studentAxis = useMemo(() => {
    let [colSort, studentAxis, xpackets] = memoKey

    if (colSort.length) {
      //console.time(TIMERS.sort)
      console.log('(4) useSort', colSort)
      // calculate scores for each packet; todo: centralize probably?
      const packetSortMap = new Map<string, Map<string, number>>()

      colSort.forEach(({ id }) => {
        const sortMap = new Map<string, number>()
        packetSortMap.set(id, sortMap)

        xpackets.forEach((item) => {
          if (item.packetId === id) {
            let value: number
            // todo: this code is in timeCell too!
            let pctQs = item.qs?.filter((q) => q)
            if (pctQs) {
              value =
                sumBy(pctQs, (q) => q.pts) / sumBy(pctQs, (q) => q._outOf_)
            }
            // todo: rubric or pts sort?
            // else {
            //   value = item.tag
            // }
            value = Number.isFinite(value) ? value : null
            sortMap.set(item.student?.id, value)
          }
        })
      })

      // perform sorts
      const orders = colSort.map((sort) => sort.order)
      // put empties to the bottom
      const noValVal = { asc: Infinity, desc: -Infinity } as const

      studentAxis = orderBy(
        studentAxis,
        colSort.map(
          (sort) => (sa) =>
            packetSortMap.get(sort.id).get(sa.id) ?? noValVal[sort.order]
        ),
        orders
      )
      //console.timeEnd(TIMERS.sort)
      console.log(colSort)
    }
    return studentAxis
  }, memoKey)

  return { colSortContext, baseData: { ...baseData, studentAxis } }
}

// todo:...needs more cleanup, but this gets types passing
const laPacketAxis: ListAdapter<PacketAxisItem> = {
  ...laPacket,
  idFromItem: (item) => item.id,
  ItemComponent: null,
  select: (item) => ({ packetId: item.id }),
}
/**
 * Generates digest
 * * Changes with packetAxis, studentAxis, xpackets
 * * No modifications
 */
function useDigest(baseData: TeaTimeBaseData): TeaGridDigest {
  const { dispatchStay, routeData } = useRouter<RD_SW_Time>()
  const { packetAxis, studentAxis, xpackets } = baseData
  const memoKey = [packetAxis, studentAxis, xpackets] as const

  const items2d = useMemo(() => {
    //console.time(TIMERS.memoItems2d)
    let packetIdxMap = new Map(packetAxis.map((pa, idx) => [pa.id, idx]))
    let studentMap = new Map<string, XpacketSW[]>(
      studentAxis.map((student) => [
        student.id,
        times(packetAxis.length, () => null),
      ])
    )
    // Make rows in student map
    xpackets.forEach((xp) => {
      const packetIdx = packetIdxMap.get(xp.packetId)
      // Only splice if packetIdx is defined
      if (packetIdx >= 0) {
        studentMap.get(xp.student.id)?.splice(packetIdx, 1, xp)
      }
    })

    // And put columns in 2d
    let items2d: XpacketSW[][] = []
    for (let y = 0; y < studentAxis.length; y++) {
      items2d[y] = studentMap.get(studentAxis[y].id)
    }
    //console.timeEnd(TIMERS.memoItems2d)
    return items2d
  }, memoKey)

  const xAxis = {
    empty: packetAxis.length === 0,
    items: packetAxis,
    ...useListCallbacks(packetAxis, laPacketAxis),
  }

  const yAxis = {
    empty: studentAxis.length === 0,
    items: studentAxis,
    ...useListCallbacks(studentAxis, laStudent),
  }

  // Initialize xpacket selection if not set
  useEffect(() => {
    if (!routeData.xpacketId) {
      // search for non-missing packet if none selected
      // start at x,y params if present
      // todo: i suppose this could be inefficient if the grid is sparse
      let x = Math.max(0, xAxis.selectedIndex)
      let y = Math.max(0, yAxis.selectedIndex)
      both: for (x; x < xAxis.items.length; x++) {
        for (y; y < yAxis.items.length; y++) {
          let item = items2d[y]?.[x]
          if (item && item.status !== 'missing') {
            console.log(`no xpacketId, setting to [${x}, ${y}]`)
            dispatchStay({
              packetId: item.packetId,
              studentId: item.student.id,
              xpacketId: item.id,
            })
            break both
          }
        }
      }
    }
  }, [routeData.xpacketId])

  return {
    empty: baseData.empty,
    items2d,
    packetNumberPrefixes: baseData.packetNumberPrefixes,
    selectItem: useCallback(
      (item: XpacketSW) =>
        dispatchStay({
          packetId: item?.packetId,
          studentId: item?.student?.id,
          xpacketId: item?.id,
        }),
      []
    ),
    sections: baseData.sections,
    selectedItem: items2d[yAxis.selectedIndex]?.[xAxis.selectedIndex],
    stds: baseData.stds,
    xAxis,
    yAxis,
  }
}

export type XYCursor = {
  move: {
    down(): void
    left(): void
    right(): void
    to(x: number, y: number): void
    up(): void
  }
  x: number
  y: number
}
/**
 * Avoid landing on missing xpackets
 * This is integrated into the TimeAirlock, so use as follows:
 * @example
 * const { xyCursor } = useTeacherTimeContext()
 */
function useXYCursor(digest: TeaGridDigest): XYCursor {
  const { selectItem } = digest
  const [[x, y], setXy, xyRef] = useStateAndRef<[x: number, y: number]>([
    digest.xAxis.selectedIndex,
    digest.yAxis.selectedIndex,
  ])

  // todo: not sure this is worth it, but allows `move` reference to be stable
  // todo: have to extract the value inside each function
  // todo: can't remember why i wanted a stable ref, so not sure the messiness is worth it
  const isThisGoingToBreakRef = useAlwaysUpdateRef(digest)

  const move = useMemo(() => {
    console.log('useXYCursor->memo', xyRef.current)

    const debouncedSelect = debounce(
      (x: number, y: number) => {
        const { items2d } = isThisGoingToBreakRef.current // todo: messy ref, see above
        selectItem(items2d[y][x])
      },
      220,
      { leading: false, trailing: true }
    )

    const to = (x: number, y: number) => {
      const { xAxis, yAxis } = isThisGoingToBreakRef.current // todo: messy ref, see above
      if (
        x >= 0 &&
        y >= 0 &&
        x < xAxis.items.length &&
        y < yAxis.items.length
      ) {
        // Set cursor immediately
        setXy([x, y])
        // Debounce the more expensive select
        debouncedSelect(x, y)
      }
    }

    const next = (axis: 'x' | 'y', dir: -1 | 1) => () => {
      const { items2d, xAxis, yAxis } = isThisGoingToBreakRef.current // todo: messy ref, see above
      // todo: this is probably worse than having separate horiz/vert...
      const [x, y] = xyRef.current
      const isX = axis === 'x'
      const current = isX ? x : y
      const searchEnd = isX ? xAxis.items.length : yAxis.items.length
      for (let i = current + dir; i >= 0 && i < searchEnd; i += dir) {
        const candidate = isX ? items2d[y][i] : items2d[i][x]
        // Allow landing on missing with Illuminate since those get marked
        // todo: should precalculate marks and check that instead
        if (candidate && (candidate?.status !== 'missing' || candidate?.qs)) {
          isX ? to(i, y) : to(x, i)
          return
        }
      }
    }

    return {
      down: next('y', 1),
      left: next('x', -1),
      right: next('x', 1),
      to,
      up: next('y', -1),
    }
  }, [selectItem]) //[items2d, selectItem, xAxis.items, yAxis.items])

  ///////////////////////////
  // Ensure we're in sync with the selected item
  // (?) but default to [0,0]
  ///////////////////////////
  const { items2d, xAxis, yAxis } = digest // todo: destructuring down here so don't accidentally use the wrong version above...
  useEffect(() => {
    if (items2d) {
      if (
        xAxis.selectedIndex < 0 ||
        xAxis.selectedIndex !== x ||
        yAxis.selectedIndex < 0 ||
        yAxis.selectedIndex !== y
      ) {
        move.to(
          Math.max(0, xAxis.selectedIndex),
          Math.max(0, yAxis.selectedIndex)
        )
      }
    }
  }, [xAxis.selectedIndex, yAxis.selectedIndex])

  return { move, x, y }
}
