import { Box, BoxProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { AppTitle, HStack } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { useHorribleFadeIn } from '~src/utils/useAnimation'

type TableLayoutProps = {
  filters?: ReactNode
  gridProps?: BoxProps
  pickers: ReactNode
  table: ReactNode
  title: string | string[]
}

/** Table layout with slots for filters and pickers used by Home and Setup */
export function TableLayout(props: TableLayoutProps) {
  const { filters, gridProps, pickers, table, title } = props

  return (
    <AppTitle title={title}>
      <Box
        display="grid"
        gridRowGap={6}
        gridTemplateRows="max-content 1fr"
        height="100%"
        overflowX="auto"
        overflowY="hidden"
        pb={6}
        width="100%"
        {...(gridProps ?? {})}
      >
        <HStack flexWrap="wrap" gap={8} justifyContent="center" p={1}>
          <HStack flexWrap="wrap" gap={4} justifyContent="center">
            {pickers}
          </HStack>
          {filters}
        </HStack>
        <Box fontSize="sm" overflow="hidden">
          {table}
        </Box>
      </Box>
    </AppTitle>
  )
}

type TableContainerProps = BoxProps & {
  qResult?: {
    error?: unknown
    isError?: boolean
    isFetching: boolean
    isPending: boolean
    isSuccess: boolean
  }
  /** Show loading dots when re-fetching (for slow fetches) */
  hideWhenFetching?: boolean
}

/**
 * Table container with default BoxProps for vertical scroll
 * You can specify any props, but I had specifically called out
 *  * `flexGrow`
 *  * `fontSize`
 *  * `maxWidth`
 *  * `px`
 */
export function TableContainer(props: TableContainerProps) {
  const { children, hideWhenFetching, qResult, ...boxProps } = props
  const opacity = useHorribleFadeIn(true) // todo: why is this here if i hardcoded it to `true`?

  let body: ReactNode
  if (!qResult) {
    body = children // if no qResult provided, just show the children
  } else if (qResult.isPending || (hideWhenFetching && qResult.isFetching)) {
    body = <LoadingDots />
  } else if (qResult.isError) {
    // todo: handle non-success states correctly
    throw qResult.error
  } else {
    body = children
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      opacity={opacity}
      overflowY="hidden"
      transition="opacity 400ms ease-in"
      {...boxProps}
    >
      {body}
    </Box>
  )
}
