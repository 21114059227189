import { Box, BoxProps, ButtonProps } from '@chakra-ui/react'
import { StrId } from '@paper/schema'
import { useVirtualizer, VirtualizerOptions } from '@tanstack/react-virtual'
import {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useAlwaysUpdateRef } from '~src/utils/useRefs'
import { Txt } from './txt'

const spacingsY = {
  '240': 240,
  'airy+': 56,
  airy: 48,
  normal: 40,
  snug: 36,
}

export type ColumnProps = {
  align?: 'start' | 'center' | 'end'
  dontElipsize?: boolean
  opacity?: number
  width?: number
}
export type UghColumn<T> = {
  cell(item: T, idx: number): ReactNode
  label(): ReactNode
  props?: ColumnProps
  getProps?(item: T): ColumnProps
}

type GetId<T> = (item: T) => any

type TableContext<T = any> = {
  cell: { px: number }
  colorScheme: string
  columns: UghColumn<T>[]
  row: {
    baseHeight: number
    // todo: this api is WIP
    Expandee: UghTableProps<T>['Expandee']
    expandeeHeight: number
    getId: GetId<T>
    gridTemplateColumns: string
    onSelect(item: T): void
    rowFormatter?: UghTableProps<T>['rowFormatter']
  }
}

export type ExpandeeProps<T> = {
  height: number
  item: T
  index: number
}

const TableContext = createContext<TableContext>(undefined)

type UghTableProps<T> = {
  'aria-label': string
  colorScheme?: ButtonProps['colorScheme']
  columns: UghColumn<T>[]
  data: T[]
  Expandee?: FC<ExpandeeProps<T>>
  expandeeHeight?: number
  flexGrow?: BoxProps['flexGrow']
  flexShrink?: BoxProps['flexShrink']
  getId?: GetId<T>
  height?: BoxProps['height']
  noFr?: boolean
  onSelect?(item: T): void
  overscan?: number
  rowFormatter?(item: T): BoxProps
  selectedId?: string
  spacingX?: 'normal' | 'snug'
  spacingY?: keyof typeof spacingsY
}
/**
 * @react-spectrum/table was super-duper slow when filtering
 * I don't think built in <table> mojo supports virtual very well
 * For now, ignoring accessiblity/focus/etc. to get something working
 * Maybe a pay out-of-the-box component?, useTable
 */
export const UghTable = memo(function UghTable<T extends object>(
  props: UghTableProps<T>
) {
  // console.log('<UghTable />')
  let {
    columns,
    colorScheme,
    data,
    Expandee,
    expandeeHeight = 0,
    getId,
    height,
    noFr,
    onSelect,
    overscan,
    rowFormatter,
    selectedId,
    spacingX,
    spacingY,
    ...tableElProps
  } = props

  // todo: better protection around no id...
  getId = useCallback(props.getId ?? ((item: T) => (item as StrId).id), [
    props.getId,
  ])

  ////////////////////////
  // Selection
  ////////////////////////
  const onSelectRef = useAlwaysUpdateRef(onSelect)
  const selectedIndexRef = useRef<number>()
  const selectedIndex = useMemo(() => {
    let result = !selectedId
      ? -1
      : data.findIndex((p) => getId(p) === selectedId)
    selectedIndexRef.current = result
    return result
  }, [data, selectedId]) // todo: should i add getId here?

  ////////////////////////
  // Virtualization and scroll
  ////////////////////////
  const rowHeight = spacingsY[spacingY] ?? 40
  const expandedHeight = rowHeight + expandeeHeight

  const parentRef = useRef<HTMLDivElement>()

  // todo: the previous version of this hook hammered render
  // todo: i think the new version doesn't, but doesn't appear to let me change `estimateSize` on demand?
  // todo: so have to define the height here and below?
  const rv = useVirtualizer({
    count: data.length,
    estimateSize: (index) =>
      index === selectedIndexRef.current ? expandedHeight : rowHeight,
    getScrollElement: () => parentRef.current,
    overscan,
  })

  // scroll to selected
  useEffect(() => {
    // todo: how to do this properly?
    // console.time('resizeItem')
    for (let vi of rv.getVirtualItems()) {
      if (vi.index !== selectedIndex) {
        if (vi.size !== rowHeight) {
          rv.resizeItem(vi, rowHeight)
        }
      } else if (vi.size !== expandedHeight) {
        rv.resizeItem(vi, expandedHeight)
      }
    }
    // console.timeEnd('resizeItem')
    if (selectedIndex >= 0) {
      rv.scrollToIndex(selectedIndex)
    }
  }, [selectedIndex])

  ////////////////////////
  // Context
  ////////////////////////
  const ctx: TableContext<T> = useMemo(() => {
    const cellXPad = spacingX === 'snug' ? 1 : 6
    const widthPad = spacingX === 'snug' ? 0 : 48
    const gridTemplateColumns = columns
      .map((c) => {
        if (!noFr) {
          return c.props?.width
            ? `minmax(${c.props.width + widthPad}px, ${
                c.props.width >= 720
                  ? '10fr'
                  : c.props.width > 80
                  ? '2.5fr'
                  : '1fr'
              })`
            : `minmax(300px, 5fr)`
        } else {
          return `${c.props.width}px`
        }
      })
      .join(' ')

    return {
      cell: {
        px: cellXPad,
      },
      colorScheme,
      columns,
      row: {
        baseHeight: rowHeight,
        Expandee,
        expandeeHeight,
        getId,
        gridTemplateColumns,
        onSelect: (item) => onSelectRef.current?.(item),
        rowFormatter,
      },
    }
  }, [colorScheme, columns, expandeeHeight, rowHeight, spacingX])

  return (
    <TableContext.Provider value={ctx}>
      <Box
        aria-colcount={columns.length}
        aria-rowcount={data.length}
        display="grid"
        gridTemplateRows="max-content"
        height={height}
        role="grid"
        overflowX="auto"
        {...tableElProps}
      >
        <UghHeader />
        <Box ref={parentRef} role="presentation" overflowY="auto">
          {!data?.length && (
            <Txt
              data-cy="table-empty"
              fontStyle="italic"
              maxWidth={`calc(100vw - 2 * var(--chakra-space-6))`}
              opacity={0.8}
              p={6}
              textAlign="center"
            >
              Empty
            </Txt>
          )}
          <Box
            role="presentation"
            style={{ height: rv.getTotalSize(), position: 'relative' }}
          >
            {rv.getVirtualItems().map((vi) => (
              <Box
                data-index={vi.index}
                key={vi.key}
                role="presentation"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${vi.size}px`,
                  transform: `translateY(${vi.start}px)`,
                  transitionDuration: '.4s',
                  transitionProperty: 'height top',
                }}
              >
                <UghRow
                  idx={vi.index}
                  isSelected={vi.index === selectedIndex}
                  item={data[vi.index]}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </TableContext.Provider>
  )
})

/**
 * Wraps in useCallback
 */
export function useTableGetId<T extends object>(fn: GetId<T>): GetId<T> {
  return useCallback(fn, [])
}

type UghHeaderProps = {}
const UghHeader = memo(function UghHeader(props: UghHeaderProps) {
  const { columns } = useContext(TableContext)
  return (
    <Row index={-1} isHeader={true} item={null}>
      {columns.map((c, idx) => (
        <Cell
          align={c.props?.align}
          dontElipsize={c.props?.dontElipsize}
          key={idx}
          role="rowheader"
        >
          {c.label()}
        </Cell>
      ))}
    </Row>
  )
})

type UghRowProps<T> = { idx: number; isSelected: boolean; item: T }
const UghRow = memo(function UghRow<T extends object>(props: UghRowProps<T>) {
  // console.log('<UghRow />')
  const { idx, isSelected, item } = props
  const { columns, row } = useContext(TableContext)

  return (
    <Row id={row.getId(item)} index={idx} isSelected={isSelected} item={item}>
      {columns.map((c, colIdx) => (
        <Cell
          align={c.props?.align}
          dontElipsize={c.props?.dontElipsize}
          key={colIdx}
          role="gridcell"
          {...(c.getProps?.(item) ?? {})} // todo: clean up this interface!
        >
          {c.cell(item, idx)}
        </Cell>
      ))}
    </Row>
  )
})

type RowProps<T> = {
  children: ReactNode
  id?: string
  index: number
  isHeader?: boolean
  isSelected?: boolean
  item: T
}
function Row<T>(props: RowProps<T>) {
  // console.log('<Row />')
  const { id, index, isHeader, isSelected, item, ...elProps } = props
  const {
    colorScheme = 'blue',
    row: {
      baseHeight,
      Expandee,
      expandeeHeight,
      gridTemplateColumns,
      rowFormatter,
      onSelect,
    },
  } = useContext(TableContext)

  const isSelectable = !!(id && onSelect)
  // todo: making an adjustment for header for tall row
  let innerHeight =
    isHeader && baseHeight >= spacingsY['airy+'] ? 40 : baseHeight

  let bg = isSelected ? `${colorScheme}.50` : null
  let activeBg: BoxProps['bg'] = bg ?? 'gray.50'
  let customProps = rowFormatter?.(item) ?? {}

  return (
    // Needed an extra div as a shell for expanding
    <Box
      borderBottomWidth="1px"
      borderInline="4px solid transparent"
      borderInlineStartColor={isSelected && `${colorScheme}.500`}
      height="100%"
      overflowY={isSelected ? 'hidden' : undefined} // note: setting this for all caused each row to scroll horizontally individually
      role="row"
      {...customProps}
    >
      <Box
        alignItems="center"
        bg={bg}
        display="grid"
        gridTemplateColumns={gridTemplateColumns}
        height={`${innerHeight}px`}
        onClick={isSelectable ? () => onSelect(item) : null}
        justifyItems="center"
        transition="background-color 250ms ease"
        _hover={{ bg: !isSelectable ? null : activeBg }}
        {...elProps}
      />
      {isSelected && Expandee && (
        <Expandee height={expandeeHeight} index={index} item={item} />
      )}
    </Box>
  )
}

type CellProps = {
  align?: 'start' | 'center' | 'end'
  dontElipsize?: boolean
  children: ReactNode
  opacity?: number
  role: 'rowheader' | 'gridcell'
}
function Cell(props: CellProps) {
  const { align, dontElipsize, ...elProps } = props
  const { px } = useContext(TableContext).cell
  return (
    <Box
      // alignItems="center"
      // display="flex"
      justifySelf={align}
      maxWidth="100%"
      overflow="hidden"
      px={px}
      py={1}
      textAlign={align}
      textOverflow={dontElipsize ? null : 'ellipsis'}
      whiteSpace="nowrap"
      {...elProps}
    />
  )
}
