/**
 * Keep track of invalidation keys here
 */
export type InvalidateKey =
  | 'dir_cst'
  | 'fixit'
  | 'login'
  | 'move'
  | 'pe'
  | 'pin'
  | 'scanlog'
  | 'setup_school'
  | 'setup_curriculum'
  | 'setup_curriculum_variants'
  | 'xpacket'

export type ApiApp = 'api' | 'imgp_run' | 'print_generate'
