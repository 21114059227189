import { Grid, List, ListItem, Tag, TagProps } from '@chakra-ui/react'
import { IcoMoreTooltip } from '@paper/icons'
import { DiffedQ, DiffStatus, QType, Question, ValidatedQ } from '@paper/schema'
import { DEFAULT_FG } from '@paper/styles'
import { Fragment } from 'react'
import { QuestionRow, QuestionRowAllResponses } from '~src/blocks/answerKey'
import { HStack, Mono, TooltippedIcon, Txt, VStack } from '~src/components'
import { getSampleString } from '~src/utils/messages'
import { MatchIcon, ValidationIcon } from './formQuestionStatusIcons'

type QuestionImportDetailsProps = { data: DiffedQ }

export function QuestionImportDetails(props: QuestionImportDetailsProps) {
  const { dvResult } = props.data
  const rows = [
    'Source variants',
    'Validation status',
    'Match status',
    'Label',
    'Type',
    '/ Pts',
    'Responses',
    'Standards',
  ]
  const gridTemplateColumns = `auto repeat(${dvResult.length}, 1fr)`
  const gridTemplateRows = `repeat(${rows.length}, auto)`

  const fields: [IQComp, keyof Question][] = [
    [IQLabel, 'label'],
    [IQType, 'type'],
    [IQOutOf, 'outOf'],
    [IQResponses, 'responses'],
    [IQStdsList, 'stds'],
  ]

  return (
    <Grid
      alignItems="center"
      fontSize="sm"
      gridAutoFlow="column"
      gridGap={8}
      gridTemplateRows={gridTemplateRows}
      gridTemplateColumns={gridTemplateColumns}
      justifyItems="center"
      overflowY="auto"
      p={2}
    >
      {rows.map((label) => (
        <Txt fontWeight={400} key={label}>
          {label}
        </Txt>
      ))}
      {dvResult.map((vQ, cIdx) => {
        const diffStatus: DiffStatus =
          cIdx === 0
            ? 'ref'
            : vQ?.hash === dvResult[0]?.hash
            ? 'match'
            : 'doesntMatch'
        return (
          <Fragment key={vQ.keys.join('')}>
            <ImportKeysList keys={vQ.keys} />
            <ValidationIcon isValid={vQ.isValid} withTooltip={true} />
            <MatchIcon status={diffStatus} withTooltip={true} />
            {fields.map(([Component, field]) => (
              <QDetailsCell
                key={field}
                candidateIdx={cIdx}
                Component={Component}
                field={field}
                dQ={props.data}
                vQ={vQ}
              />
            ))}
          </Fragment>
        )
      })}
    </Grid>
  )
}

type IQComp = (q: Question) => JSX.Element
export const makeQTableCell =
  (Component: IQComp) => (item: DiffedQ | Question) => {
    let q = item && ('q' in item ? item.q : item?.responses ? item : null)
    return q && <Component {...q} />
  }

type QDetailsCellProps = {
  candidateIdx: number
  Component: IQComp
  dQ: DiffedQ
  field: keyof Question
  vQ: ValidatedQ
}

function QDetailsCell(props: QDetailsCellProps) {
  const { candidateIdx, Component, dQ, field, vQ } = props
  let doesntMatch = !!dQ.fieldMap[field]?.[candidateIdx]
  return (
    <VStack gap={1}>
      <HStack gap={1}>
        {doesntMatch && <MatchIcon status="doesntMatch" withTooltip={true} />}
        {makeQTableCell(Component)(vQ?.value)}
      </HStack>
      <Txt color="red.500" fontSize="xs">
        {vQ?.errors?.[field]?.[0]}
      </Txt>
    </VStack>
  )
}

const tagProps: TagProps = {
  fontFamily: 'mono',
  fontWeight: 700,
  justifyContent: 'center',
  variant: 'solid',
}

const qTypeColor = (type: QType): TagProps['colorScheme'] => {
  switch (type) {
    case 'GRID':
      return 'teal'
    case 'OER':
      return 'gray'
    case 'MC':
      return 'purple'
    default:
      return 'red'
  }
}

export function IQLabel(q: Question) {
  let colorScheme = qTypeColor(q.type)
  return (
    <Tag {...tagProps} colorScheme={colorScheme} width="36px">
      {q.label}
    </Tag>
  )
}

export function IQType(q: Question) {
  return <Mono>{q.type}</Mono>
}

export function IQResponses(q: Question) {
  return (
    <HStack gap={0}>
      <QuestionRow data={q} noBorder={true} noLabel={true} />
      {q.type !== 'OER' && q.responses.length > 1 && (
        <TooltippedIcon
          // todo: maybe this should be a buttoned-popup?
          aria-label="All responses"
          as={IcoMoreTooltip}
          fontSize="lg"
          tooltipProps={{
            bg: 'gray.100',
            color: { DEFAULT_FG },
            label: (
              <VStack p={1}>
                <QuestionRowAllResponses data={q} />
              </VStack>
            ),
          }}
        />
      )}
    </HStack>
  )
}

export function IQOutOf(q: Question) {
  let value = `${q.outOf === 0 ? q.maxPts : ''}/${q.outOf}`
  return <Mono>{value}</Mono>
}

export function IQStdsSummary(q: Question) {
  return (
    <Mono>{getSampleString({ items: q.stds, toString: (item) => item })}</Mono>
  )
}

export function IQStdsList(q: Question) {
  return (
    <List fontFamily="mono" fontSize="xs">
      {q.stds?.map((std) => <ListItem key={std}>{std}</ListItem>)}
    </List>
  )
}

export function ImportKeysList(props: { keys: string[] }) {
  const { keys } = props
  return (
    <List fontFamily="mono" fontSize="xs">
      {keys.map((key) => (
        <ListItem key={key}>{key}</ListItem>
      ))}
    </List>
  )
}
