import { css } from '@emotion/react'

export const globalStyles = css`
  *,
  *:before,
  *:after,
  *::placeholder {
    box-sizing: inherit;
  }

  html,
  body {
    color: rgba(0, 0, 0, 0.64);
    font-weight: 300;
    height: 100%;
    margin: 0;
    overflow: hidden; /* No scroll bounce */
  }

  /* TODO: How do I replace the chakra defaults? */
  button[type='button'] {
    cursor: pointer;
    font-weight: 300;
  }

  button[type='reset'] {
    font-weight: 300;
  }

  button[type='submit'] {
    font-weight: 400;
  }

  #root,
  #overlay-provider {
    height: 100%;
  }

  .recharts-responsive-container {
    overflow: hidden;
  }

  div[data-radix-portal] {
    z-index: 1400 !important;
  }
`
