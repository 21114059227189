import * as Yup from 'yup'
import { PdfContentSet, PdfStyle } from '..'
import { yupPage } from './v-pe_answerKey'
import { yupMin0Int } from './v-utils'

export const yupPdfStyle = Yup.mixed<PdfStyle>()

export const yupPdfContentSet = Yup.object<PdfContentSet>({
  name: Yup.string().required('Name is required'),
  pages: Yup.array(yupPage).required(),
  parts: Yup.array(yupMin0Int.required()).required(),
  style: yupPdfStyle.required(),
  type: Yup.string().oneOf(['assessment', 'ticket']).required(),
})

export const yupStds = Yup.array(Yup.string().required()).required()
