import { APIs, BodyOf } from '@paper/api-specs'
import { sumByPrec } from '@paper/utils'
import { sumBy } from 'lodash'
import { useApiQuery } from '~src/data/useApiQuery'

export const useSatelliteAQLookup = (body: BodyOf<'pe.assessment.lookup'>) => {
  const { title } = body

  const qResult = useApiQuery({
    apiSpec: APIs['pe.assessment.lookup'],
    queryVars: { body },
    queryFn: async ({ plainFetch }) => {
      let data = await plainFetch()
      if (!data) {
        return null
      }

      let da = data
      let foundCount = sumBy(da.candidates, (c) => c.keys.length)
      // todo: would be nice if the stats acknowledged when these don't match
      let questionsForStats = da.candidates[0]?.questions ?? []
      let questionCount = questionsForStats.length
      let ptsSum = sumByPrec(questionsForStats, (q) => q?.outOf ?? 0)
      let issueCount = 0

      da.diffedQs.forEach((dQ) => {
        if (dQ.status === 'doesntMatch') {
          issueCount += 1
        }
        if (!dQ.isValid) {
          issueCount += 1
        }
      })

      return { ...da, foundCount, issueCount, ptsSum, questionCount }
    },
    useQueryProps: { enabled: !!title },
  })

  return qResult
}

export type SatelliteAQQResult = ReturnType<typeof useSatelliteAQLookup>
