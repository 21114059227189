import { APIs, BodyOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { Curriculum, SetupCourse } from '@paper/schema'
import { getActiveCurriculumPred, pushToMap, sortNumeric } from '@paper/utils'
import { produce } from 'immer'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { useApiMutation, useApiQuery } from '~src/data/useApiQuery'
import { RD_Setup_School } from '~src/routelist'
import { useFilters } from '~src/utils/useFilters'

export type SchoolSetupData = SetupCourse[]

export const useSchoolSetupBaseData = () => {
  return useApiQuery({
    apiSpec: APIs['setup.schoolbase'],
    queryVars: { body: undefined },
    queryFn: async ({ plainFetch }) => {
      const data = await plainFetch()
      let { allCurricula, schools, ...rest } = data

      // setup up curricula for lookups
      const activeCurricula = allCurricula.filter(
        getActiveCurriculumPred(data.syId)
      )

      const curriculumMap = new Map(allCurricula.map((c) => [c.id, c]))

      const activeFamilyEdMap = new Map<string, Curriculum[]>()
      activeCurricula.forEach((c) =>
        pushToMap(activeFamilyEdMap, `${c.family}, ${c.ed}`, c)
      )

      // sort schools
      schools = sortNumeric(schools, (sch) => sch.id)

      return {
        activeCurricula,
        activeFamilyEdMap,
        curriculumMap,
        schools,
        ...rest,
      }
    },
    useQueryProps: {},
  })
}

export const useSingleSchoolSetupData = (schoolId: string) => {
  const { routeData } = useRouter<RD_Setup_School>()
  const { f_status, f_text } = routeData
  const filters = useFilters()

  const qResult = useApiQuery({
    apiSpec: APIs['setup.school.get'],
    queryVars: { searchParams: { schoolId } },
    queryFn: async ({ plainFetch }) => {
      let courses = await plainFetch()
      // sort by hasAnyRegistered, then name
      courses = sortNumeric(courses, (cou) => cou.name)
      courses = orderBy(courses, (c) => c.stats.paper === 0)
      return courses
    },
    useQueryProps: { enabled: !!schoolId },
  })

  let data = useMemo(() => {
    let filtered = qResult.data
    if (f_status) {
      filtered = filtered?.filter((p) => p.status === f_status)
    }
    if (f_text) {
      filtered = filtered?.filter((p) =>
        filters.startsWithByWord(p.name, f_text)
      )
    }
    return filtered
  }, [qResult.data, f_status, f_text])

  return { ...qResult, data }
}

export function useCourseSubmitter() {
  return useApiMutation({
    apiSpec: APIs['setup.school.set'],
    preMutationFn: (data: SetupCourse) => {
      const json: BodyOf<'setup.school.set'> = {
        id: data.id,
        name: data.name,
        sections: data.sections.map((ss) => {
          const { isEnabled, meta, teacherId } = ss
          return {
            isEnabled,
            section: produce(meta.section, (draft) => {
              // copy on updateable values
              draft.name = isEnabled ? draft.name : '[DELETING]' // stick a value here so passes validation
              // update curriculumIds with selection
              draft.curriculumId = data.curriculumId
              // update teacher with selection
              if (draft.teacher.id !== teacherId) {
                if (meta.leadTeacher.id !== teacherId) {
                  throw Error(
                    `Unexpected teacher ${teacherId}/${meta.leadTeacher.id}`
                  )
                }
                draft.teacher = meta.leadTeacher
              }
            }),
          }
        }),
      }
      return json
    },
    useMutationProps: { throwOnError: true },
  })
}

// const TMP_COURSES = [
//   'AP Biology',
//   'AP Calculus AB',
//   'AP Chemistry',
//   'AP Computer Science Principles',
//   'AP English Language and Composition',
//   'AP English Literature and Composition',
//   'AP Physics I',
//   'AP Seminar',
//   'AP Spanish Language',
//   'AP US Government and Politics',
//   'AP United States History',
//   'AP World History: Modern',
//   'Accelerated Precalculus',
//   'Algebra I',
//   'Algebra II',
//   'Ancient World History',
//   'Biology',
//   'Chemistry',
//   'English I',
//   'English II',
//   'English III',
//   'English IV',
//   'Geometry',
//   'Latin American History',
//   'Physics',
//   'Spanish I',
//   'Spanish II',
//   'Spanish III',
// ]

// type ProtoCourse = {
//   stats?: Partial<SetupCourse['stats']>
//   variants?: string[]
// }

// const anotherStub = (schoolId: string) => {
//   const syId = 2023
//   const term: Term = {
//     code: formatSchoolYear(syId),
//     firstDay: new Date(`${syId - 1}-08-01`),
//     lastDay: new Date(`${syId}-06-30`),
//     syId,
//   }

//   const courseMap = new Map<string, SetupCourse>()
//   const curriculumMap = new Map<string, Curriculum>()
//   const teacherMap = new Map<string, Teacher>()

//   // Scenarios
//   let protoCourses: ProtoCourse[] = [
//     { stats: { paper: 3 } }, // All sections active
//     { stats: { paper: 2 } }, // Some sections active
//     { stats: { deleted: 1, paper: 4 } }, // Deleted section
//     { stats: { dirty: 1, paper: 3 } }, // Dirty section
//     { stats: { deleted: 1, dirty: 1, paper: 4 } }, // Deleted and dirty
//     { variants: ['NY', 'USI'], stats: { paper: 3 } }, // Multiple curricula
//   ]

//   // Generate courses and curricula
//   TMP_COURSES.forEach((course, idx) => {
//     const spec = protoCourses[idx] ?? {}

//     // Merge stats with defaults
//     const stats = {
//       deleted: 0,
//       dirty: 0,
//       exists: 3,
//       paper: 0,
//       ...(spec.stats ?? {}),
//     }

//     // make curricula
//     const curriculumIds = []
//     for (let variant of spec.variants ?? ['USI']) {
//       const ed = term.code
//       let curriculum = makeC2({
//         _status: 'active',
//         ed,
//         family: course,
//         levels: ['HS'],
//         subject: 'todo:',
//         variant,
//       })

//       curriculumIds.push(curriculum.id)
//       curriculumMap.set(curriculum.id, curriculum)
//     }

//     // make course
//     let setupCourse: SetupCourse = {
//       curriculumIds: stats.paper > 0 ? curriculumIds : [], // only attach curriculumIds if registered
//       id: course,
//       name: course,
//       ...spec,
//       sections: [],
//       stats,
//       status: getCourseStatus(stats),
//     }

//     courseMap.set(setupCourse.id, setupCourse)

//     // todo: move this
//     const makeTeacher = (name: string, email?: string): Teacher => {
//       const names = name.split(/\s/)
//       const lastName = names.pop()
//       const firstName = names.join(' ')

//       email ??= `${firstName}.${lastName}@test.ponder.co`
//       return {
//         email,
//         firstName,
//         // todo: using obfuscateEmailForId breaks the tests, probably a build problem
//         id: email, // obfuscateEmailForId(email),
//         lastName,
//       }
//     }

//     const SAM_PELL = makeTeacher('Sam Teacher', 'sam.teacher@ponder.co')
//     const coteacher = makeTeacher('Cornelius Cauliflower Coteacher')

//     const teachers = [SAM_PELL, coteacher]

//     // make sections
//     // first, initialize statuses from stats
//     let statuses = times(stats.exists, (): SetupSection['status'] => 'inactive')
//     // add deleted
//     times(stats.deleted, () => statuses.push('deleted'))
//     // add exists_in_sis+paper
//     times(stats.paper - stats.deleted, (i) => (statuses[i] = 'active'))
//     // mark dirty
//     times(stats.dirty, (i) => (statuses[i] = 'dirty'))

//     statuses.forEach((status, i) => {
//       // defaults for active
//       let curriculumIds = setupCourse.curriculumIds
//       let id = `stub-${course}-${i}`
//       let leadTeacher = SAM_PELL
//       let name = `Team ${i}`
//       let sectionTeacher = SAM_PELL

//       if (status === 'inactive') {
//         curriculumIds = null
//         name = null
//       } else if (status === 'deleted') {
//         leadTeacher = null
//       } else if (status === 'dirty') {
//         leadTeacher = coteacher
//       }

//       setupCourse.sections.push({
//         id,
//         isEnabled: status !== 'inactive',
//         leadTeacher,
//         nickname: null,
//         section: {
//           curriculumIds,
//           course,
//           id,
//           name,
//           schoolId,
//           schoolName: 'todo:entangle',
//           students: [], // todo:
//           teacher: sectionTeacher,
//           teachers,
//           term,
//         },
//         status,
//       })
//     })
//   })

//   return {
//     courseMap,
//     courses: Array.from(courseMap.values()),
//     curricula: Array.from(curriculumMap.values()),
//     curriculumMap,
//     syId,
//   }
// }

// /*
// todo: possibly useful for api
//   let courses = entries(groupBy(allSections, (s) => s.section.course)).map(
//     ([course, sections]) => {
//       // crunch counts
//       let stats = { deleted: 0, dirty: 0, exists: 0, paper: 0 }
//       for (let { status } of sections) {
//         if (status === 'deleted') {
//           stats.deleted += 1
//           stats.paper += 1
//         } else {
//           stats.exists += 1
//           if (status !== 'inactive') {
//             stats.paper += 1
//             if (status === 'dirty') {
//               stats.dirty += 1
//             }
//           }
//         }
//       }

//       let result: SetupCourse = {
//         curriculumIds: sections[0]?.section.curriculumIds ?? [],
//         id: course,
//         name: course,
//         sections,
//         stats,
//         status: getCourseStatus(stats),
//       }

//       return result
//     }
//   )

//   return { courses }
//   */
