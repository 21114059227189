import {
  IconButton,
  IconButtonProps,
  Input,
  InputProps,
} from '@chakra-ui/react'
import { IcoArrowForward } from '@paper/icons'
import { format } from 'date-fns'
import { useStateAndPropForm } from '~src/utils/useState'
import { HStack } from './stack'

const DateFormats = {
  date: `yyyy-MM-dd`,
  'datetime-local': `yyyy-MM-dd'T'HH:mm`,
}

export const toInputDateString = (
  date: number | Date,
  type: keyof typeof DateFormats
) => {
  return !date ? null : format(date, DateFormats[type])
}
export const today_yyyy_mm_dd = () => toInputDateString(new Date(), 'date')
export const now_datetime_local = () =>
  toInputDateString(new Date(), 'datetime-local')

type DateValue = {
  string: string
  userTz: number
}

type DateInputProps = {
  iconButtonProps?: Partial<Omit<IconButtonProps, 'onClick'>>
  max: 'today' | string
  onChange(value: DateValue): void
  size?: InputProps['size']
} & (
  | { type?: 'date'; value: string }
  | { type: 'datetime-local'; value: number }
)

/**
 * @deprecated Workaround since native Input fires onChange a lot
 */
export function BadDateInput(props: DateInputProps) {
  let { iconButtonProps, max, onChange, size = 'md', type = 'date' } = props
  // todo: unhardcode
  const min = type === 'date' ? '2023-09-01' : '2023-09-01T00:00'

  const strValue =
    typeof props.value === 'string'
      ? props.value
      : toInputDateString(props.value, 'datetime-local')
  const { localValue, setLocalValue } = useStateAndPropForm(strValue)

  if (max === 'today') {
    max = today_yyyy_mm_dd()
  } else if (max === 'now') {
    max = now_datetime_local()
  }

  return (
    <HStack gap={1}>
      <Input
        max={max}
        min={min}
        onChange={(event) => {
          // fire on clear
          if (event.target.value === '') {
            onChange(null)
          } else {
            setLocalValue(event.target.value)
          }
        }}
        size={size}
        type={type}
        value={localValue}
      />
      <IconButton
        aria-label="Submit date"
        colorScheme="blue"
        icon={<IcoArrowForward />}
        isDisabled={!localValue || localValue === strValue}
        onClick={() => {
          onChange({
            string: localValue,
            // this is a value like "2024-01-01T08:75" in the user's timezone
            // as opposed to UTC
            userTz: new Date(localValue).valueOf(),
          })
        }}
        size={size}
        {...(iconButtonProps ?? {})}
      />
    </HStack>
  )
}

export function BadDateTimeInput(props: DateInputProps) {}
