/**
 * Returns a single item from the set
 * @example
 * if (mySet.length !== 1) {
 *  throw Error(`There should only be one...`)
 * }
 * let item = itemFromSet(mySet)
 */
export function itemFromSet<T>(set: Set<T>): T {
  for (let item of set) {
    return item
  }
}

/**
 * Returns unique items via set after applying `callback` to `arr`
 */
export function uniqOf<T, U>(
  arr: T[],
  callback: (item: T, index: number) => U
): U[] {
  return Array.from(new Set(arr.map(callback)))
}

/**
 * Returns count of unique items
 */
export function uniqByCount<T>(
  arr: T[],
  callback?: (item: T, index: number) => any
): number {
  let iterable = callback ? arr.map(callback) : arr
  return new Set(iterable).size
}

/**
 * Returns single item from set if unique or null
 */
export function uniqOrNull<T>(set: Set<T>): T {
  return set.size === 1 ? itemFromSet(set) : null
}

/**
 * Returns unique set of "records"
 * todo: Assumes items are something like `{packetId: 'x', teacherId: 'y'}
 * todo: Assumes that keys are sorted
 */
export function uniqRecords<T extends object, U = T>(
  items: T[],
  callback?: (item: T, index: number) => U
) {
  let map = new Map<string, U>()
  items.forEach((t, idx) => {
    let u = callback ? callback(t, idx) : (t as any as U)
    let key = Object.values(u).join(':')
    map.set(key, u)
  })

  return [...map.values()]
}
