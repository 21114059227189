import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { IcoGoogle } from '@paper/icons'
import { LoginData } from '@paper/schema'
import { createFetch, fetcher } from '@paper/utils'
import {
  CodeResponse,
  GoogleOAuthProvider,
  useGoogleLogin,
} from '@react-oauth/google'
import { useState } from 'react'
import { AppTitle } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import config from '~src/utils/config'
import { setWindowIfCypress } from '~src/utils/cypress'

type PublicProps = { onLogin(user: LoginData): void }

export function Public(props: PublicProps) {
  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <AppTitle title="Sign in" />
      <PublicGoogle {...props} />
    </GoogleOAuthProvider>
  )
}

/**
 * @react-oauth/google supports Google's new login mojo
 * but requires being wrapped in <GoogleOAuthProvider />
 */
function PublicGoogle(props: PublicProps) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const handleError = (msg?: string) => {
    setError(msg || `We unexpectedly couldn't sign you in :(`)
    setLoading(false)
  }

  const handleSignIn = async (payload: CodeResponse) => {
    setLoading(true)
    try {
      // Finish login on the server
      const user = await APIs['auth.googletoken'].fetch({
        fetchAs: createFetch({ origin: config.api.prefix }),
        queryVars: { body: payload },
      })

      // And update user context on the client
      props.onLogin(user)
    } catch (err) {
      // Use response messge for 401 (e.g. unsupported domain)
      if (err.response?.status === 401) {
        handleError(await err.response.json())
      } else {
        console.error(err)
        handleError()
      }
    }
  }

  // note: Not sure how/where to stub for testing google signin
  setWindowIfCypress('__ugly_public_test_hook', { handleSignIn })

  const login = useGoogleLogin({
    flow: 'auth-code',
    onNonOAuthError(onNonOAuthError) {
      console.log('onNonOAuthError', onNonOAuthError)
      handleError(onNonOAuthError.type)
    },
    onSuccess: handleSignIn,
    onError(errorResponse) {
      console.log('onError', errorResponse)
      handleError(errorResponse.error_description)
    },
  })

  // @react-oauth doesn't expose whether the library is loaded
  // but the hook does re-render the component, so should be safe-ish to check here
  // @ts-expect-error
  const isGoogleReady = !!window.google?.accounts

  return (
    <FullPageLoading qResult={{ isPending: !isGoogleReady }}>
      <VStack
        color="blackAlpha.700"
        height="100%"
        justifyContent="center"
        margin="auto"
        maxWidth="400px"
        spacing="5"
      >
        <Heading as="h1" color="black">
          Paper
        </Heading>
        <Text fontSize="sm">Sign in below with your school account</Text>
        <Button
          data-cy="gsignin"
          fontWeight="light"
          isLoading={loading}
          leftIcon={<IcoGoogle />}
          loadingText="Sign in with Google"
          onClick={() => {
            setLoading(true)
            // we were previously calling `setLoading(true)` here
            // we previously got an error when the user closed the popup
            // but that doesn't seem to be the case in the new world
            // https://github.com/MomenSherif/react-oauth/issues/44
            // this appears to be fixed as new now have onNonOAuthError
            login()
          }}
          variant="outline"
        >
          Sign in with Google
        </Button>
        <Box color="red.500" display="flex" minHeight="100px">
          <Text as="span" textAlign="center">
            {error}
          </Text>
        </Box>
      </VStack>
    </FullPageLoading>
  )
}
