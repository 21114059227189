import { ApiDef } from '../defineApi'
import { auth_checktoken, auth_googletoken, auth_inttoken } from './spec.auth'
import {
  dir_list,
  dir_list_packets,
  dir_list_packets_blanks,
} from './spec.directory'
import { external_pq } from './spec.external'
import { fixit_get, fixit_update } from './spec.fixit'
import {
  imgp_batch_create,
  imgp_batch_intook,
  imgp_rerun,
  imgp_run,
} from './spec.imgp'
import { load_meta_get } from './spec.loader'
import { movepages_list_students, movepages_move } from './spec.movepages'
import { pl_illuminate } from './spec.packet'
import {
  pe_assessment_lookup,
  pe_getPackets,
  pe_publish,
  pe_submitAnswerKey,
  pe_submitCrossPacket,
  pe_submitPdf,
  pe_submitStds,
  pe_verifyPdf,
} from './spec.packetentry'
import { unstable_pin_list, unstable_pin_set } from './spec.pin'
import { print_download, print_generate } from './spec.print'
import { roster_get } from './spec.roster'
import {
  qrbackup_lookup,
  sb_list,
  xpacketSets,
  xpage_fix,
} from './spec.scanlog'
import { scan_viz } from './spec.scanViz'
import { sch_manifests_get, sch_pg_get, sch_print_list } from './spec.school'
import {
  setup_admin_get,
  setup_admin_set,
  setup_curriculm_get,
  setup_curriculum_submit,
  setup_issues,
  setup_school_base,
  setup_school_get,
  setup_school_set,
  setup_variants_get,
  setup_variants_set,
} from './spec.setup'
import { signUrls } from './spec.signurls'
import { std_list } from './spec.std'
import { sticker_generate, sticker_validate } from './spec.sticker'
import { packet_fixer_get, packet_fixer_update } from './spec.unstable'
import { xpackets_list, xpackets_list_student } from './spec.xpacket'

// todo: how to organize?
// todo: currently, most apis are built for a very specific purpose
// todo: as opposed to resource based GET/PUT/POST/DELETE for each curricula, packets, xpackets, etc.
export const APIs = {
  // auth
  'auth.checktoken': auth_checktoken,
  'auth.googletoken': auth_googletoken,
  'auth.inttoken': auth_inttoken,

  // blank packets for link api landing
  blanks: dir_list_packets_blanks,

  // directory
  'dir.list': dir_list,
  'dir.listPackets': dir_list_packets,
  'dir.listXpackets': xpackets_list,
  'dir.listXpacketsStudent': xpackets_list_student,

  // external apis
  'external.pq': external_pq,

  // fix-it for tickets
  'fixit.get': fixit_get,
  'fixit.update': fixit_update,

  // imgp
  'imgp.batch.create': imgp_batch_create,
  'imgp.batch.intook': imgp_batch_intook,
  'imgp.rerun': imgp_rerun,
  'imgp.run': imgp_run,

  // load meta
  'loadmeta.get': load_meta_get,

  // move pages (nostudents)
  'movepages.move': movepages_move,
  'movepages.studentList': movepages_list_students,

  // secret packet fixer
  'packet.fixer.get': packet_fixer_get,
  'packet.fixer.update': packet_fixer_update,

  // todo: illuminate api
  'packets.illuminate': pl_illuminate,

  // packet entry
  'pe.assessment.lookup': pe_assessment_lookup,
  'pe.getPackets': pe_getPackets,
  'pe.publish': pe_publish,
  'pe.submitAnswerKey': pe_submitAnswerKey,
  'pe.submitCrossPacket': pe_submitCrossPacket,
  'pe.submitPdf': pe_submitPdf,
  'pe.submitStds': pe_submitStds,
  'pe.verifyPdf': pe_verifyPdf,

  // experiment with pinning
  'pin.list': unstable_pin_list,
  'pin.set': unstable_pin_set,

  // bulk print todo:
  'print.download': print_download,
  'print.generate': print_generate,

  // school/bulk print todo:
  'roster.get': roster_get,

  // scan log
  'scanlog.batchList': sb_list,
  'scanlog.xpacketSets': xpacketSets,
  'scanlog.qrbLookup': qrbackup_lookup,
  'scanlog.xpageFix': xpage_fix,

  // scan viz experiment
  'scanViz.list': scan_viz,

  // school/bulk print todo:
  'school.generatestatus': sch_manifests_get,
  'school.listpackets': sch_print_list,
  'school.packetgroup': sch_pg_get,

  // setup
  'setup.admin.get': setup_admin_get,
  'setup.admin.set': setup_admin_set,
  'setup.curriculum.get': setup_curriculm_get,
  'setup.curriculum.submit': setup_curriculum_submit,
  'setup.school.get': setup_school_get,
  'setup.school.set': setup_school_set,
  'setup.schoolbase': setup_school_base,
  'setup.issues': setup_issues,
  'setup.variants.get': setup_variants_get,
  'setup.variants.set': setup_variants_set,
  'std.list': std_list,

  // sign urls
  signurls: signUrls,

  // sticker experiment
  'sticker.generate': sticker_generate,
  'sticker.validate': sticker_validate,
}

/** Key into APIs definition */
export type ApiKey = keyof typeof APIs

export type ApiFromKey<K extends ApiKey> = (typeof APIs)[K] extends ApiDef<
  infer A
>
  ? A
  : never

// type Of<
//   K extends ApiKey,
//   ATTR extends keyof ApiDefBase,
// > = ApiFromKey<K>[ATTR]

export type BodyOf<K extends ApiKey> = ApiFromKey<K>['body']

export type ResultOf<K extends ApiKey> = ApiFromKey<K>['result']
