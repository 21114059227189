import { LoadMeta, LoadSchedule, SchoolYear } from '@paper/schema'
import { defineApi } from '../defineApi'

export type LoadRun = Pick<LoadMeta, 'counts' | 'runStart' | 'status'>
export type LoadStatus = {
  issueCount: number
  lastRun: LoadRun
  lastSuccess: number
}

export const load_meta_get = defineApi<{
  method: 'post'
  body: {}
  result: {
    jobs: {
      enrollments: LoadStatus
      scores: LoadStatus
    }
    loadSched: LoadSchedule[]
    loadYear: SchoolYear
  }
}>({
  method: 'post',
  urlFactory: `loadmeta/get`,
})
