import type { DirPacket, XpacketSW } from '@paper/schema'
import { setDefault, setDefaultMap } from '@paper/utils'
import { orderBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { ListAdapter, Success, useListCallbacks } from '~src/blocks/list'
import { QAgg, StdAgg } from '~src/pages/sw/jumpTo/bargraph/bargraphTypes'
import { RD_SW_JumpToStd } from '~src/routelist'
import { NO_STD } from '~src/utils/messages'

type RouteData = RD_SW_JumpToStd

export const NR_LABEL = '∅'

/** Calculate pct, returning 0 for non-finite values #226 */
const finitePct = (agg: Pick<QAgg, 'outOf' | 'pts'>) => {
  let pct = (100 * agg.pts) / agg.outOf
  return Number.isFinite(pct) ? pct : 0
}

// todo: using these off-label...
const laQ: ListAdapter<QAgg, RouteData> = {
  id: 'qAgg',
  idFromItem: (item) => item.id,
  idFromRouter: (routeData) => routeData.qId,
  ItemComponent: null,
  itemName: 'questions',
  // reset answer filter when switching questions
  select: (item) => ({ f_ans: null, qId: item.id }),
}

const laStd: ListAdapter<StdAgg, RouteData> = {
  id: 'stdAgg',
  idFromItem: (item) => item.std,
  idFromRouter: (item) => item.std,
  ItemComponent: null,
  itemName: 'standards',
  select: (item) => ({
    f_ans: null,
    qId: item.qs[0]?.id,
    std: item.std,
  }),
}

type ClientDigest<Item = any, OtherData = any> = {
  adapter: ListAdapter<Item>
  success: Success<Item, OtherData>
}

export type QDigest = ClientDigest<QAgg>
export type StdDigest = ClientDigest<StdAgg>

/**
 * Returns memoized StdAgg[], QAgg[], and their list utils
 */
export function useQStdDigests(
  packet: DirPacket,
  xpackets: XpacketSW[]
): { qDigest: QDigest; stdDigest: StdDigest } {
  const hasData = packet && xpackets

  // todo: overlap with useClientCrunch
  const { qAggs, stdAggs } = useMemo(() => {
    return crunchQs(packet, xpackets)
  }, [packet, xpackets])

  const qListCallbacks = useListCallbacks(qAggs, laQ)
  const stdListCallbacks = useListCallbacks(stdAggs, laStd)

  // Override std prev/next
  // todo: maybe not a good idea to override...
  const baseStdPrevNext = [stdListCallbacks.onPrev, stdListCallbacks.onNext]
  const [stdQPrev, stdQNext] = [-1, 1].map((dir, idx) => {
    return useCallback(() => {
      const stdIndex = stdListCallbacks.selectedIndex
      const { qs } = stdAggs[stdIndex]
      const qIndex = qs.findIndex(
        (item) => item.id === qListCallbacks.selectedId
      )
      const nextQ = qs[qIndex + dir]
      // Go to nextQ if it exists
      if (nextQ) {
        qListCallbacks.onSelect(nextQ)
      }
      // Else go to next standard
      else {
        baseStdPrevNext[idx]()
      }
    }, [
      // note: uhh, i think all the other references are stable...
      // todo: maybe not worth the bug risk
      qListCallbacks.selectedId,
      stdListCallbacks.selectedItem,
      stdListCallbacks.selectedIndex,
    ])
  })

  stdListCallbacks.onPrev = stdQPrev
  stdListCallbacks.onNext = stdQNext

  return {
    qDigest: {
      adapter: laQ,
      success: !hasData
        ? null
        : {
            empty: qAggs.length === 0,
            items: qAggs,
            ...qListCallbacks,
          },
    },
    stdDigest: {
      adapter: laStd,
      success: !hasData
        ? null
        : {
            empty: stdAggs.length === 0,
            items: stdAggs,
            ...stdListCallbacks,
          },
    },
  }
}

function crunchQs(packet: DirPacket, xpackets: XpacketSW[]) {
  const pmQs = packet?.questions
  const hasQuestions = pmQs?.length > 0

  const qMap = new Map<string, QAgg>()
  const stdMap = new Map<string, StdAgg>()

  if (hasQuestions && xpackets) {
    console.log('Running crunchQs')
    //////////////////////////////////////
    // Go through each student->question
    //////////////////////////////////////
    for (const xpacket of xpackets) {
      pmQs.forEach((pmQ, qIndex) => {
        const xpQ = xpacket.qs?.[qIndex]

        // correct bubble set
        // todo: this doesn't support multiple correct answers, should migrate the [...] from question row
        // todo: i have similar code to this for answer key QuestionRow
        let topResponse = pmQ.responses.find((p) => p.pts === pmQ.maxPts)
        // todo: corner case where this could be empty since illuminate doesn't guarantee responses match
        // todo: how does this work with grid?
        const correctSet = new Set(topResponse?.filledStr.split(''))
        const outOf = pmQ.outOf
        const pts = xpQ?.pts ?? 0

        // question aggs
        const qAgg = setDefaultMap(qMap, pmQ.id, {
          answerCounts: [],
          answerDenom: 0,
          id: pmQ.id,
          label: pmQ.label,
          outOf: 0,
          pct: 0,
          pts: 0,
          qIndex,
          stds: pmQ.stds ?? [],
          stuMap: new Map(),
          type: pmQ.type,
        })

        // set student-centric value
        const studentId = xpacket.student?.id
        if (studentId) {
          qAgg.stuMap.set(studentId, {
            filledStr: xpQ?.filledStr,
            outOf,
            pct: finitePct({ outOf, pts }),
            pts,
          })
        }

        // question-centric
        // only count this student if they have xpacket data
        if (xpQ) {
          xpQ._outOf_ = pmQ.outOf // tack on outOf here since it's convenient
          qAgg.outOf += outOf
          qAgg.pts += pts

          // answer aggs
          const incrAnswer = (idx: number) => {
            const rawLabel = pmQ.options[idx]?.label
            const label = rawLabel ?? '∅' // NR
            const aAgg = setDefault(qAgg.answerCounts, idx, {
              count: 0,
              isCorrect: correctSet.has(label),
              label,
            })
            aAgg.count += 1
            qAgg.answerDenom += 1
          }

          // Aggregate answers
          if (pmQ.type === 'GRID') {
            const label = xpQ.pts.toString()
            const idx = xpQ.pts
            const aAgg = setDefault(qAgg.answerCounts, idx, {
              count: 0,
              isCorrect: xpQ.pts >= pmQ.outOf,
              label,
            })
            aAgg.count += 1
            qAgg.answerDenom += 1
          } else {
            xpQ?.filled.forEach(incrAnswer)
            // Have a non-repsonse filter
            if (xpQ?.filled.length === 0) {
              incrAnswer(pmQ.options.length)
            }
          }
        }
      })
    }
  }

  //////////////////////////////////////
  // Finish question aggregates
  //////////////////////////////////////
  let qAggs = Array.from(qMap.values())
  for (const qAgg of qAggs) {
    // Unsparsify answers for grid-in (todo: maybe change this to sort/arrayify later)
    qAgg.answerCounts = qAgg.answerCounts.filter((p) => p)
    // Calculate percent
    qAgg.pct = finitePct(qAgg)
    // "unwind" by standard
    const stds = qAgg.stds.length ? qAgg.stds : [NO_STD]
    stds.forEach((std) => {
      const stdAgg = setDefaultMap(stdMap, std, {
        label: std,
        outOf: 0,
        pct: null,
        pts: 0,
        qs: [],
        std,
      })
      stdAgg.outOf += qAgg.outOf
      stdAgg.pts += qAgg.pts

      stdAgg.qs[qAgg.qIndex] = qAgg
    })
  }

  //////////////////////////////////////
  // Finish standard aggregates
  //////////////////////////////////////
  let stdAggs = Array.from(stdMap.values())
  for (const stdAgg of stdAggs) {
    // Calculate pct
    stdAgg.pct = finitePct(stdAgg)
    // Unsparsify qs
    stdAgg.qs = stdAgg.qs.filter((p) => p)
  }

  //////////////////////////////////////
  // Sorting
  //////////////////////////////////////
  qAggs = orderBy(qAggs, (qAgg) => qAgg.qIndex)
  stdAggs = orderBy(stdAggs, (stdAgg) => stdAgg.pct)

  return { qAggs, stdAggs }
}
