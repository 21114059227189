import { routemeta } from '@paper/route'
import { CurriculumAirlock } from '~src/blocks/curriculumAirlock'
import { swAdapters, SWProvider } from '~src/blocks/swContext'
import { TeacherAirlock } from '~src/blocks/teacherAirlock'
import { Home } from '~src/pages/home'
import { HomeRedirect, HomeRedirectNoYear } from '~src/pages/home/redirect'
import { SecretScanVizPage } from '~src/pages/internal/scanViz/scanViz'
import { PacketFixPage } from '~src/pages/internal/secretPacketFix'
import { LinkApi } from '~src/pages/linkApi'
import { PinGridPage } from '~src/pages/pinGrid/pinGrid'
import { IlluminateLookupPage } from '~src/pages/publish/illuminateLookup/illuminateLookup'
import { PublishProvider } from '~src/pages/publish/publishProvider'
import { AdminSetupPage } from '~src/pages/setup/admin/adminSetup'
import { CurriculumSetup } from '~src/pages/setup/curriculum/curriculumSetup'
import { CurriculumSetupDialogProvider } from '~src/pages/setup/curriculum/dialogs'
import { SetupIssues } from '~src/pages/setup/issues/setupIssues'
import { SchoolSetup } from '~src/pages/setup/school/schoolSetup'
import { SchoolSetupProvider } from '~src/pages/setup/school/schoolSetupContext'
import { StickerPage } from '~src/pages/sticker/stickerPage'
import { FixitPage } from '~src/pages/sw/fixit'
import { FixitProvider } from '~src/pages/sw/fixit/fixitProvider'
import { JumpTo } from '~src/pages/sw/jumpTo'
import { RubricPage } from '~src/pages/sw/rubric/rubricPage'
import { Scanlog } from '~src/pages/sw/scanlog'
import { ScanlogAirlock } from '~src/pages/sw/scanlog/scanlogAirlock'
import { SetStudentView } from '~src/pages/sw/setstudent'
import { TimeGridView } from '~src/pages/sw/timeGrid'
import { z_home_curriculum, z_home_school, z_home_teacher } from './z_home'
import { z_internal_packetfix, z_internal_scanviz } from './z_internal'
import {
  z_crossNetwork,
  z_illuminateLookup,
  z_linkApi,
  z_publish,
} from './z_misc'
import { z_setup_curriculum, z_setup_issues, z_setup_school } from './z_setup'
import {
  z_sw_fixit,
  z_sw_jumpToQ,
  z_sw_jumpToStd,
  z_sw_rubric,
  z_sw_scanlog,
  z_sw_setStudent,
  z_sw_time,
} from './z_sw'
import { z_year } from './z_year'

const empty = routemeta({
  path: '/',
  render() {
    return <HomeRedirectNoYear />
  },
  yearType: 'timeless',
})

const root = routemeta({
  path: '/:yearCode',
  pathParams: z_year,
  render() {
    return <HomeRedirect />
  },
  yearType: 'switchable-sw-2',
})

const home_teacher = routemeta({
  path: '/:yearCode/teacher',
  pathParams: z_year,
  queryParams: z_home_teacher,
  yearType: 'switchable-sw-2',
  render: () => <Home />,
})

const home_curriculum = routemeta({
  path: '/:yearCode/curriculum',
  pathParams: z_year,
  queryParams: z_home_curriculum,
  yearType: 'switchable-pe-all',
  render: () => <Home />,
})

const home_school = routemeta({
  path: '/:yearCode/school',
  pathParams: z_year,
  queryParams: z_home_school,
  yearType: 'switchable-sw-2',
  render: () => <Home />,
})

const sw_fixit = routemeta({
  parent: home_teacher,
  path: '/fixit',
  pathParams: z_year,
  queryParams: z_sw_fixit,
  render() {
    return (
      <TeacherAirlock behavior="unlocked-clear-curriculum">
        <SWProvider adapter={swAdapters.ticketPilot}>
          <FixitProvider>
            <FixitPage />
          </FixitProvider>
        </SWProvider>
      </TeacherAirlock>
    )
  },
  yearType: 'specific-sw',
})

const sw_jumpToQ = routemeta({
  parent: home_teacher,
  path: '/jump-to-q',
  pathParams: z_year,
  queryParams: z_sw_jumpToQ,
  render() {
    return (
      <TeacherAirlock behavior="unlocked-dont-clear-curriculum">
        <SWProvider adapter={swAdapters.jumpTo}>
          <JumpTo />
        </SWProvider>
      </TeacherAirlock>
    )
  },
  yearType: 'specific-sw',
})

const sw_jumpToStd = routemeta({
  parent: home_teacher,
  path: '/jump-to-std',
  pathParams: z_year,
  queryParams: z_sw_jumpToStd,
  render() {
    return (
      <TeacherAirlock behavior="locked">
        <SWProvider adapter={swAdapters.jumpTo}>
          <JumpTo />
        </SWProvider>
      </TeacherAirlock>
    )
  },
  yearType: 'specific-sw',
})

const sw_rubric = routemeta({
  parent: home_teacher,
  path: '/rubric',
  pathParams: z_year,
  queryParams: z_sw_rubric,
  render() {
    return (
      <TeacherAirlock behavior="locked">
        <SWProvider adapter={swAdapters.ticketPilot}>
          <RubricPage />
        </SWProvider>
      </TeacherAirlock>
    )
  },
  yearType: 'specific-sw',
})

const sw_scanlog = routemeta({
  parent: home_teacher,
  path: '/scanlog',
  pathParams: z_year,
  queryParams: z_sw_scanlog,
  render() {
    return (
      <TeacherAirlock behavior="unlocked-clear-curriculum">
        <SWProvider adapter={swAdapters.scanlog}>
          <ScanlogAirlock>
            <Scanlog />
          </ScanlogAirlock>
        </SWProvider>
      </TeacherAirlock>
    )
  },
  yearType: 'specific-sw-1',
})

const sw_setStudent = routemeta({
  parent: home_teacher,
  path: '/setstudent',
  pathParams: z_year,
  queryParams: z_sw_setStudent,
  render() {
    return (
      <TeacherAirlock behavior="locked">
        <SWProvider adapter={swAdapters.nostudent}>
          <SetStudentView />
        </SWProvider>
      </TeacherAirlock>
    )
  },
  yearType: 'specific-sw-1',
})

const sw_time = routemeta({
  parent: home_teacher,
  path: '/time',
  pathParams: z_year,
  queryParams: z_sw_time,
  render() {
    return (
      <TeacherAirlock behavior="locked">
        <SWProvider adapter={swAdapters.time}>
          <TimeGridView />
        </SWProvider>
      </TeacherAirlock>
    )
  },
  // exact packet won't be present, but probably no need to boot
  yearType: 'switchable-sw-2',
})

const setup_admin = routemeta({
  path: '/setup/admin',
  render() {
    return <AdminSetupPage />
  },
  yearType: 'timeless',
})

const setup_curriculum = routemeta({
  path: '/setup/curriculum',
  queryParams: z_setup_curriculum,
  render() {
    return (
      <CurriculumSetupDialogProvider>
        <CurriculumSetup />
      </CurriculumSetupDialogProvider>
    )
  },
  yearType: 'timeless',
})

const setup_issues = routemeta({
  path: '/setup/issues',
  queryParams: z_setup_issues,
  render() {
    return <SetupIssues />
  },
  yearType: 'timeless',
})

const setup_school = routemeta({
  path: '/:yearCode/setup/school',
  pathParams: z_year,
  queryParams: z_setup_school,
  render() {
    return (
      <SchoolSetupProvider>
        <SchoolSetup />
      </SchoolSetupProvider>
    )
  },
  yearType: 'specific-load',
})

const crossNetwork = routemeta({
  path: '/:yearCode/network',
  queryParams: z_crossNetwork,
  pathParams: z_year,
  render() {
    return <PinGridPage />
  },
  yearType: 'specific-sw',
})

const linkApi = routemeta({
  path: '/linkapi/v1/illuminate/:_illuminate',
  ...z_linkApi,
  render() {
    return <LinkApi />
  },
  yearType: 'timeless',
})

const publish = routemeta({
  parent: home_curriculum,
  path: '/:curriculumId/publish/:contentIdOrNew',
  ...z_publish,
  render() {
    return (
      <CurriculumAirlock behavior="locked">
        <PublishProvider />
      </CurriculumAirlock>
    )
  },
  yearType: 'specific-pe', // todo: figure out if curriculum etc. is timeless
  // todo: if this is timeless, it can't have a yeared parent
})

const illuminateLookup = routemeta({
  path: '/illuminate-lookup',
  queryParams: z_illuminateLookup,
  render() {
    return <IlluminateLookupPage />
  },
  yearType: 'timeless',
})

const internal_packetfix = routemeta({
  path: '/internal/packetfix',
  queryParams: z_internal_packetfix,
  render() {
    return <PacketFixPage />
  },
  yearType: 'timeless',
})

const internal_scanviz = routemeta({
  path: '/internal/scanviz',
  queryParams: z_internal_scanviz,
  render() {
    return <SecretScanVizPage />
  },
  yearType: 'timeless',
})

const sticker = routemeta({
  path: '/:yearCode/sticker',
  pathParams: z_year,
  queryParams: z_home_school,
  render() {
    return (
      <TeacherAirlock behavior="unlocked-clear-curriculum">
        <StickerPage />
      </TeacherAirlock>
    )
  },
  yearType: 'specific-sw',
})

/**
 * @example
 * import { Routes} from 'here'
 * const { dispatchRoute } = useRouter()
 * // Route.home_teacher knows its type
 * dispatchRoute(Routes.home_teacher.mergeAction({ routeSpecificData }))
 */
export const Routes = {
  empty,
  root,
  crossNetwork,
  home_teacher,
  home_curriculum,
  home_school,
  illuminateLookup,
  internal_packetfix,
  internal_scanviz,
  linkApi,
  publish,
  setup_admin,
  setup_curriculum,
  setup_issues,
  setup_school,
  sticker,
  sw_fixit,
  sw_jumpToQ,
  sw_jumpToStd,
  sw_rubric,
  sw_scanlog,
  sw_setStudent,
  sw_time,
}
