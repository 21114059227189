import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { HStack, Txt } from '~src/components'

type StdTagListProps = { stds: string[] } & (
  | { onRemove(item: string): void; readonly: false }
  | { onRemove?: undefined; readonly: true }
)

export function StdTagList(props: StdTagListProps) {
  const { onRemove, readonly, stds } = props
  return (
    <HStack flexWrap="wrap" gap={1.5}>
      {!stds?.length ? (
        <Txt fontSize="sm" fontStyle="italic" opacity=".8">
          No standards selected for this ticket.
        </Txt>
      ) : (
        stds.map((std) => (
          <Tag
            key={std}
            borderRadius="full"
            colorScheme="blackAlpha"
            size="md"
            variant="solid"
          >
            <TagLabel>{std}</TagLabel>
            {!readonly && <TagCloseButton onClick={() => onRemove(std)} />}
          </Tag>
        ))
      )}
    </HStack>
  )
}
